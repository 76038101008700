import React, { useState, createContext, useEffect } from "react";
import { makeApiRequest } from "../../utils/ApiHelper";
import { OVERALL_CHANNEL_ENDPOINT } from "../../EndPoints";

export const OverallChannelMetricsContext = createContext();

export const OverallChannelMetricsContextProvider = props => {
  const [channelGridData, setChannelGridData] = useState([]);
  const [topLineMetricsData, setTopLineLevelData] = useState({});
  const [isLoading, setIsLoading] = useState({ loading: true });

  useEffect(() => {
    const currentRange = props.activeDateRange && props.activeDateRange.range;
    const url = OVERALL_CHANNEL_ENDPOINT + "?range=" + currentRange;
    makeApiRequest(url, "get", {
      cmpId: ["topline", "channel"],
      setIsLoading: setIsLoading
    }).then(data => {
      const channelData =
        data && data.data && data.data.data && data.data.data.channels;
      const topLevelAgreegatedData =
        data && data.data && data.data.data && data.data.data.totals;
      setChannelGridData(channelData);
      setTopLineLevelData(topLevelAgreegatedData);
      setIsLoading({ loading: false });
    });
  }, [props.activeDateRange]);

  return (
    <OverallChannelMetricsContext.Provider
      value={{
        topLineMetricsData: topLineMetricsData,
        channelGridData: channelGridData,
        isLoading: isLoading.loading
      }}
    >
      {props.children}
    </OverallChannelMetricsContext.Provider>
  );
};
