import React from "react";
import { Container } from "react-bootstrap";
import ChannelGraphs from "./ChannelGraphs";
import ChannelGrid from "./ChannelGrid";

export default function Channel({ sectionHeading, activeDateRange }) {
  return (
    <div id="channel" className="section-style gray">
      <Container
        className="channel-section-container"
        style={{ letterSpacing: ".1em" }}
      >
        <h1 className="text-center heading">{sectionHeading}</h1>
        <ChannelGrid activeDateRange={activeDateRange} />
        <ChannelGraphs activeDateRange={activeDateRange} />
      </Container>
    </div>
  );
}
