import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";
import GridGenerator from "../../generator/grid/GridGenerator";
import { numberFormatter } from "../../../utils/Helper";
import { OverallChannelMetricsContext } from "../../../contexts/home/OverallChannelMetricsContext";
import { __CHANNEL_GRID_TOOLTIP_INFO__ } from "../../../constants/Constants";

export default function ChannelGrid() {
  const { channelGridData, topLineMetricsData, isLoading } = useContext(
    OverallChannelMetricsContext
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Channel",
        accessor: "channel",
        minWidth: 170,
        tdClassName: "black-text text-left",
        Footer: info => {
          return <span className="black-text">Total</span>;
        }
      },
      {
        Header: "Est. Revenue Delivered",
        accessor: "revenueDelivered",
        minWidth: 114,
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.revenueDelivered,
              thousandSeparator: true,
              prefix: "$",
              noDigitsAfterDecimal: true
            });
          }
        },
        Footer: info => {
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: topLineMetricsData.revenueDelivered,
                thousandSeparator: true,
                prefix: "$",
                noDigitsAfterDecimal: true
              })}
            </span>
          );
        }
      },
      {
        Header: "Revenue Goal",
        accessor: "revenueForecasted",
        minWidth: 110,
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.revenueForecasted,
              thousandSeparator: true,
              prefix: "$",
              noDigitsAfterDecimal: true
            });
          }
        },
        Footer: info => {
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: topLineMetricsData.revenueForecasted,
                thousandSeparator: true,
                prefix: "$",
                noDigitsAfterDecimal: true
              })}
            </span>
          );
        }
      },
      {
        Header: "Booked Revenue",
        accessor: "revenuePlanned",
        minWidth: 110,
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.revenuePlanned,
              thousandSeparator: true,
              prefix: "$",
              noDigitsAfterDecimal: true
            });
          }
        },
        Footer: info => {
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: topLineMetricsData.revenuePlanned,
                thousandSeparator: true,
                prefix: "$",
                noDigitsAfterDecimal: true
              })}
            </span>
          );
        }
      },
      {
        Header: "Delivered vs. Booked",
        accessor: "paceBookedPercent",
        tdClassName: "text-right",
        minWidth: 120,
        Cell: ({ row }) => {
          if (row && row.values) {
            // let extraClass = "black-text";
            // if (row.values.paceBookedPercent > 99) {
            //   extraClass = "green-text";
            // }
            return numberFormatter({
              type: "number",
              val: row.values.paceBookedPercent,
              thousandSeparator: false,
              suffix: "%",
              digitsAfterDecimal: 1
            });
          }
        },
        Footer: info => {
          const total = topLineMetricsData.paceBookedPercent;
          let extraClass = "black-text";
          // if (total > 99) {
          //   extraClass = "green-text";
          // }
          return (
            <>
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: true,
                suffix: "%",
                extraClass: extraClass,
                digitsAfterDecimal: 1
              })}
            </>
          );
        }
      },{
        Header: "Delivered vs. Goal",
        accessor: "paceForeCastPercent",
        tdClassName: "text-right",
        minWidth: 110,
        Cell: ({ row }) => {
          if (row && row.values) {
            // let extraClass = "black-text";
            // if (row.values.paceForeCastPercent > 99) {
            //   extraClass = "green-text";
            // }
            return numberFormatter({
              type: "number",
              val: row.values.paceForeCastPercent,
              thousandSeparator: false,
              suffix: "%",
              // extraClass: extraClass,
              digitsAfterDecimal: 1
            });
          }
        },
        Footer: info => {
          const total = topLineMetricsData.paceForeCastPercent;
          let extraClass = "black-text";
          // if (total > 99) {
          //   extraClass = "green-text";
          // }
          return (
            <>
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: false,
                suffix: "%",
                extraClass: extraClass,
                digitsAfterDecimal: 1
              })}
            </>
          );
        }
      },
      {
        Header: "Booked vs. Goal",
        tdClassName: "text-right",
        accessor: "bookedForecastPercent",
        //minWidth: 130,
        Cell: ({ row }) => {
          if (row && row.values) {
            // let extraClass = "black-text";
            // if (row.values.bookedForecastPercent > 99) {
            //   extraClass = "green-text";
            // }
            return numberFormatter({
              type: "number",
              val: row.values.bookedForecastPercent,
              thousandSeparator: false,
              suffix: "%",
              // extraClass: extraClass,
              digitsAfterDecimal: 1
            });
          }
        },
        Footer: info => {
          const total = topLineMetricsData.bookedForecastPercent;
          let extraClass = "black-text";
          // if (total > 99) {
          //   extraClass = "green-text";
          // }
          return (
            <>
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: false,
                suffix: "%",
                extraClass: extraClass,
                digitsAfterDecimal: 1
              })}
            </>
          );
        }
      },
      {
        Header: "Impressions",
        accessor: "impressionsDelivered",
        tdClassName: "text-right",
        minWidth: 120,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.impressionsDelivered,
              thousandSeparator: true,
              noDigitsAfterDecimal: true
            });
          }
        },
        Footer: info => {
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: topLineMetricsData.impressionsDelivered,
                thousandSeparator: true,
                noDigitsAfterDecimal: true
              })}
            </span>
          );
        }
      },
      {
        Header: "Avg. Sell Rate",
        accessor: "avgSaleRate",
        tdClassName: "text-right",
        //minWidth: 110,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.avgSaleRate,
              thousandSeparator: true,
              prefix: "$"
            });
          }
        },
        Footer: info => {
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: topLineMetricsData.avgSaleRate,
                thousandSeparator: true,
                prefix: "$"
              })}
            </span>
          );
        }
      },
      {
        Header: "Margin",
        //minWidth: 130,
        accessor: "marginPercent",
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.marginPercent,
              thousandSeparator: false,
              suffix: "%",
              digitsAfterDecimal: 1
            });
          }
        },
        Footer: info => {
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: topLineMetricsData.marginPercent,
                thousandSeparator: false,
                suffix: "%",
                digitsAfterDecimal: 1
              })}
            </span>
          );
        }
      }
    ],
    [topLineMetricsData]
  );

  return (
    <div>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <GridGenerator
          columns={columns}
          data={channelGridData}
          colMinWidth={100}
          colWidth={50}
          gridHeight={
            channelGridData && channelGridData.length > 8
              ? 400
              : channelGridData.length * 45
          }
          virtualizedRow={true}
          cellHeight={45}
          gridLabel="Channel Grid"
          gridId="channel-grid"
          exportGridId="allchannelsmetrics"
          hoverableRow={true}
          hoveredClickableCell={"channel"}
          queryKey="channel"
          toolTipInfo={__CHANNEL_GRID_TOOLTIP_INFO__}
          totals={topLineMetricsData}
        />
      )}
    </div>
  );
}
