import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import GridGenerator from "../../generator/grid/GridGenerator";
import { numberFormatter } from "../../../utils/Helper";
import { makeApiRequest } from "../../../utils/ApiHelper";
import { __CHANNEL_GRID_TOOLTIP_INFO__ } from "../../../constants/Constants";
import { ACCOUNT_ENDPOINT } from "../../../EndPoints";
import { showToast } from "../../../utils/ApiHelper";

export default function AccountGrid({ activeDateRange }) {
  const [accountGridData, setAccountGridData] = useState([]);
  const [accountGridDataTotals, setAccountGridDataTotals] = useState({});
  const [isLoading, setIsLoading] = useState({ loading: true });
  useEffect(() => {
    const currentRange = activeDateRange && activeDateRange.range;
    const url = ACCOUNT_ENDPOINT + "?range=" + currentRange;
    makeApiRequest(url, "get", {
      cmpId: ["account"],
      setIsLoading: setIsLoading
    }).then(data => {
      const apiResponse = data && data.data && data.data.data;
      if (apiResponse) {
        setAccountGridData(apiResponse.accounts);
        setAccountGridDataTotals(apiResponse.totals);
      } else {
        showToast(
          "error",
          "Oops, there was an issue getting data for the Accounts grid. If this message persists please contact your administrator."
        );
      }
      setIsLoading({ loading: false });
    });
  }, [activeDateRange]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Account",
        accessor: "account",
        minWidth: 250,
        tdClassName: "black-text text-left",
        Footer: info => {
          return <span className="black-text">Total</span>;
        }
      },
      {
        Header: "Est. Revenue Delivered",
        accessor: "revenueDelivered",
        minWidth: 150,
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.revenueDelivered,
              thousandSeparator: true,
              prefix: "$",
              noDigitsAfterDecimal: true
            });
          }
        },
        Footer: info => {
          const total = accountGridDataTotals["revenueDelivered"];

          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: true,
                prefix: "$",
                noDigitsAfterDecimal: true
              })}
            </span>
          );
        }
      },
      {
        Header: "Impressions",
        accessor: "impressionsDelivered",
        tdClassName: "text-right",
        minWidth: 150,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.impressionsDelivered,
              thousandSeparator: true,
              noDigitsAfterDecimal: true
            });
          }
        },
        Footer: info => {
          const total = accountGridDataTotals["impressionsDelivered"];
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: true,
                noDigitsAfterDecimal: true
              })}
            </span>
          );
        }
      },
      {
        Header: "Avg. Sell Rate",
        accessor: "avgSaleRate",
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.avgSaleRate,
              thousandSeparator: true,
              prefix: "$"
            });
          }
        },
        Footer: info => {
          const total = accountGridDataTotals["avgSaleRate"];

          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: true,
                prefix: "$"
              })}
            </span>
          );
        }
      },
      {
        Header: "Margin",
        accessor: "marginPercent",
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.marginPercent,
              thousandSeparator: false,
              suffix: "%",
              digitsAfterDecimal: 1
            });
          }
        },
        Footer: info => {
          const total = accountGridDataTotals["marginPercent"];

          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: false,
                suffix: "%",
                digitsAfterDecimal: 1
              })}
            </span>
          );
        }
      },
      {
        Header: "impressionsPlanned",
        accessor: "impressionsPlanned",
        hide: true
      },
      {
        Header: "revenuePlanned",
        accessor: "revenuePlanned",
        hide: true
      }
    ],
    [accountGridDataTotals]
  );

  return (
    <div>
      {isLoading.loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <GridGenerator
            columns={columns}
            data={accountGridData}
            colMinWidth={100}
            colWidth={100}
            gridHeight={
              accountGridData && accountGridData.length > 8
                ? 550
                : accountGridData.length * 45
            }
            virtualizedRow={true}
            cellHeight={45}
            gridLabel="Account Grid"
            gridId="account-grid"
            exportGridId="accountsmetrics"
            toolTipInfo={__CHANNEL_GRID_TOOLTIP_INFO__}
            totals={accountGridDataTotals}
          />
          <small>* Excludes Elevaate data</small>
        </>
      )}
    </div>
  );
}
