import React, { useState, useEffect } from "react";
import LineChart from "../generator/charts/LineChart";
import { Row, Col } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { makeApiRequest } from "../../utils/ApiHelper";
import { SPECIFIC_CHANNEL_GRAPHS_ENDPOINT } from "../../EndPoints";
import { showToast } from "../../utils/ApiHelper";
import moment from "moment";
import { calculateTooltipEndDate } from "../home/channel/ChannelGraphs";

function RevenueGraphSpecificChannel({ specificChannel, activeDateRange }) {
  const [channelLineChartData, setChannelLineChartData] = useState({});
  const [isLoading, setIsLoading] = useState({ loading: true });

  const parseChartData = apiData => {
    const currentRange = activeDateRange && activeDateRange.range;
    const chartdataType = apiData && apiData.chartdataType;

    const dataSetData = apiData && apiData.dataSet;

    const mappedLabels = {
      revenueForecasted: "Goal",
      revenuePlanned: "Booked",
      revenueDelivered: "Est. Delivered"
    };

    if (dataSetData) {
      const legends = [];
      const metricData = [];
      dataSetData.forEach(dataset => {
        legends.push(mappedLabels[dataset.metric] || dataset.metric);
        metricData.push(dataset.data);
      });
      const labels =
        apiData.dates && apiData.dates.map(x => moment(x).format("MM/DD/YYYY"));

      if (
        ["current_month", "current_quarter"].includes(currentRange) &&
        activeDateRange.startDate &&
        activeDateRange.endDate &&
        window["__media_dash_config__"][
          "SPECIFIC_CHANNEL_GRAPH_SHOW_WHITE_SPACE"
        ]
      ) {
        const startDate = moment(activeDateRange.startDate, "M/D/YYYY");
        const endDate = moment(activeDateRange.endDate, "M/D/YYYY");
        const differentDaysOrWeeks =
          chartdataType === "weekly" ? "weeks" : "days";
        const diffDaysOrWeeksCount = endDate.diff(
          startDate,
          differentDaysOrWeeks
        );
        labels.length = diffDaysOrWeeksCount;
        labels[diffDaysOrWeeksCount - 1] = moment(endDate).format("MM/DD/YYYY");
        metricData.forEach(dataSet => (dataSet.length = diffDaysOrWeeksCount));
      }

      return {
        lineChartData: {
          legends,
          data: metricData,
          labels,
          chartdataType
        }
      };
    } else {
      showToast(
        "error",
        "Oops, there was an issue getting data for displaying the Graph. If this message persists please contact your administrator."
      );
      return {
        lineChartData: {}
      };
    }
  };

  useEffect(() => {
    const currentRange = activeDateRange && activeDateRange.range;
    if (currentRange !== "yesterday") {
      const url =
        SPECIFIC_CHANNEL_GRAPHS_ENDPOINT +
        "?range=" +
        currentRange +
        "&channel=" +
        specificChannel;
      makeApiRequest(url, "get", {
        cmpId: "specific-channel-graph-wrapper",
        setIsLoading: setIsLoading
      }).then(data => {
        const apiData = data && data.data && data.data.data;
        const parsedApiData = parseChartData(apiData);
        setChannelLineChartData(parsedApiData.lineChartData);
        setIsLoading({ loading: false });
      });
    }
  }, [activeDateRange, specificChannel]);

  const currentRange = activeDateRange && activeDateRange.range;
  const hideCharts =
    currentRange === "yesterday" ||
    (channelLineChartData &&
      channelLineChartData.labels &&
      channelLineChartData.labels.filter(x => x).length <
        (window["__media_dash_config__"].MINIMUM_DAYS_TO_VIEW_CHANNEL_GRAPHS ||
          3));

  const backgroundColor = "#f5f5f6";

  

  let tooltipEndDate = "";
  if (channelLineChartData.chartdataType === "weekly") {
    tooltipEndDate = calculateTooltipEndDate(
      channelLineChartData.labels,
      activeDateRange.endDate
    );
  }

  return (
    <div className="container" id="specific-channel-graph-wrapper">
      {isLoading.loading && !hideCharts ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          {hideCharts ? null : (
            <>
              <Row>
                <Col
                  style={{
                    backgroundColor: backgroundColor
                  }}
                  lg={12}
                >
                  <Row className="pt-4 specific-channel-graph-title">
                    <Col className="text-md-center" md={{ span: 4, offset: 4 }}>
                      <h5>Revenue Pacing</h5>
                    </Col>
                    {/* <Col className="text-md-right" md={4}>
                      <div style={{opacity: ".7"}} className="pr-4">
                        {activeDateRange.startDate} - {activeDateRange.endDate}
                      </div>
                    </Col> */}
                  </Row>
                  <LineChart
                    data={channelLineChartData}
                    config={{
                      pointsAfterDecimal: 1,
                      summaryPointsAfterDecimal: 1,
                      legendAlignment: "start",
                      showTooltipSummary: true,
                      dateRangeEndDate: tooltipEndDate,
                      tooltipShowPercentages: false,
                      tooltipSummaryLabel: "Delivered vs. Booked",
                      calculateTooltipSummaryValue: calculateTooltipSummaryValue,
                      tooltipPrepend: "$",
                      tooltipDescriptionLabel: "Revenue Pacing",
                      width: undefined,
                      height: 400,
                      containerClassNames: "pr-4 pl-4 pb-4",
                      //backgroundColor: backgroundColor,
                      dottedTypeLine: "Goal",
                      showSquareLegendStyle: "Goal",
                      colors: ["black", "#e9b158", "#38a384"],
                      showStartAndEndDates: true,
                      yAxesGridLinesShow: true,
                      xAxesGridLinesShow: false,
                      yAxesMaxTicksLimit: 5,
                      yAxeslabelString: "Revenue ($)"
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default RevenueGraphSpecificChannel;

export const calculateTooltipSummaryValue = bodyLines => {
  const keyValues = {};
  bodyLines.forEach(body => {
    keyValues[body.toString().split(":")[0]] = body.toString().split(":")[1];
  });
  const deliveredVsBooked =
    (parseFloat(keyValues["Est. Delivered"]) /
      parseFloat(keyValues["Booked"])) *
    100;
  return isNaN(deliveredVsBooked) ? 0 : deliveredVsBooked;
};
