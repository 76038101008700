import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import "./App.scss";
import { ToastContainer, Flip } from "react-toastify";
import Auth from "./components/auth/Auth";
import LogRocket from "logrocket";
//Custom Components
import Navmenu from "./components/navbar/Navmenu";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import ChannelLevelInnerPage from "./components/channelLevelView/ChannelLevelInnerPage";
import { DateRangeContextProvider } from "./contexts/common/DateRangeContext";
import * as Analytics from './components/common/Analytics';
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  if (
    window["__media_dash_config__"]["logrocketRunUrls"].indexOf(
      window.location.host
    ) > -1
  ) {
    LogRocket.init("crisp/mediadb");
  }
  const userAuthenticated = authenticated => {
    const session = sessionStorage.getItem("session");
    if(new Date(session) < new Date()) {
      sessionStorage.setItem("session", new Date());
      const user = window["__media_dash_config__"]["user_email"];
      Analytics.track("Logged In", "Logged In");
    }
    setIsAuthenticated(authenticated);
  };
  return (
    <div className="App">
      {isAuthenticated ? (
        <div>
          <ToastContainer
            position="top-center"
            autoClose={7000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
            transition={Flip}
          />
          <DateRangeContextProvider>
            <Router>
              <Navmenu />
              <div className="app-container">
                <Switch>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route path="/channel/:id">
                    <ChannelLevelInnerPage />
                  </Route>
                  <Redirect to="/" />
                </Switch>
              </div>
            </Router>
            <div className="footer">
              <Footer />
            </div>
          </DateRangeContextProvider>
        </div>
      ) : (
        <div>
          <Auth handleAuth={userAuthenticated} />
        </div>
      )}
    </div>
  );
}

export default App;
