import API from "../utils/ApiConfig";
import { toast } from "react-toastify";
import { hideEleFromDomBySelector } from "../utils/Helper";
import { __MEDIA_DASH_ASSERT_ID__ } from "../constants/Constants";
const configVars = {
  OKTA_URL:
    window["__media_dash_config__"] &&
    window["__media_dash_config__"]["OKTA_URL"]
};
export const showToast = (type, msg) => {
  toast[type](msg, {
    draggablePercent: 60,
    error: true
  });
};
export const makeApiRequest = (url, method, params, payload) => {
  switch (method) {
    case "get":
      return API.get(url, { extraParams: params });
    case "getblob":
      return API.get(url, {responseType: 'blob', extraParams: params });
    case "post":
      return API.post(url, payload);
    default:
    // code block
  }
};

export const handleApiError = response => {
  let errorMsg =
    response &&
    response.data &&
    response.data.error &&
    response.data.error.comment +
      " - " +
      response.data.error.externalSystemError;

  const authenticationFailed =
    response &&
    response.data &&
    response.data.error &&
    response.data.error.externalSystemError;
  const cmpId =
    response &&
    response.config &&
    response.config.extraParams &&
    response.config.extraParams.cmpId;
  showToast("error", errorMsg);

  if (authenticationFailed && authenticationFailed.includes("Failed")) {
    localStorage.removeItem(__MEDIA_DASH_ASSERT_ID__);
    window.location.href = configVars["OKTA_URL"];
  }

  if (cmpId) {
    if (Array.isArray(cmpId)) {
      cmpId.forEach(id => {
        hideEleFromDomBySelector("#" + id + " .spinner-border");
      });
    } else {
      hideEleFromDomBySelector("#" + cmpId + " .spinner-border");
    }
  }
};
