import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import * as Analytics from "../common/Analytics";
import { channelMapper } from "../../../src/constants/Constants";


function HoverableTableRow({
  history,
  routeTo,
  row,
  index,
  curRowProps,
  style,
  queryToBePassed,
  hoveredClickableCell,
  gridLabel
}) {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const onTableRowClick = (e, a, v) => {
    const rawQuery =
      e.currentTarget &&
      e.currentTarget.parentElement &&
      e.currentTarget.parentElement["dataset"] &&
      e.currentTarget.parentElement["dataset"]["rawQuery"];
    // const originalQuery =
    //   e.currentTarget &&
    //   e.currentTarget["dataset"] &&
    //   e.currentTarget["dataset"]["originalQuery"];
    window.scrollTo(0, 0);
    const channelValueMapper = Object.assign({}, ...Object.values(channelMapper).map(x => ({[x.query]: x.name})));
    Analytics.track("Click " + channelValueMapper[rawQuery] + " Row", gridLabel + " Row Drill Down");
    history.push("/" + routeTo + "/" + rawQuery);
  };
  return (
    <div
      data-raw-query={queryToBePassed.convertedQuery}
      // data-original-query={queryToBePassed.originalQuery}
      className={
        "tr" +
        (!(index % 2) ? " striped-white" : "")
      }
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      {...curRowProps({
        style
      })}
    >
      {row.cells.map(cell => {
        let cellProps = cell.getCellProps();
        const extraTdClass = cell.column.tdClassName
          ? cell.column.tdClassName
          : "";
        const isClickableCell = cell.column.id === hoveredClickableCell;
        return (
          <div
            onClick={isClickableCell ? onTableRowClick : null}
            className={"td " + extraTdClass + (hovered && isClickableCell ? " hovered" : "")}
            {...cellProps}
          >
            {cell.render("Cell")}
          </div>
        );
      })}
    </div>
  );
}

export default withRouter(HoverableTableRow);
