import React, { useContext, useEffect, useRef, useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import DateRangeDropdown from "../common/DateRangeDropdown";
import AnchorLink from "../../utils/AnchorSmoothScrollLink";
import { DateRangeContext } from "../../contexts/common/DateRangeContext";
import { lastApiCallValid } from "../../utils/Helper";
import { useLocation, withRouter } from "react-router-dom";
import ChannelDropdown from "./ChannelDropdown";
import {
  __LAST_SUCCESSFUL_API_CALL_MADE__,
  __MEDIA_DASH_ASSERT_ID__
} from "../../constants/Constants";
import * as Analytics from "../common/Analytics";
const configVars = {
  OKTA_URL:
    window["__media_dash_config__"] &&
    window["__media_dash_config__"]["OKTA_URL"]
};
function Navmenu({ history }) {
  if (
    window["__media_dash_config__"]["googleAnalyticsUrls"].indexOf(
      window.location.host
    ) > -1
  ) {
    history.listen((location) => {
      if(window.gtag && location.pathname !== localStorage.getItem("lastPageVisted")){
        window.gtag('config', window.__media_dash_config__.googleAnalyticsID, {
          'page_title' : location.pathname,
          'page_path': location.pathname
        });
        localStorage.setItem("lastPageVisted", location.pathname);
      }
    });
  }

  const { dateRangeHandler, activeDateRange, ranges } = useContext(
    DateRangeContext
  );

  const urlInfo = useLocation();
  const windowPath = urlInfo && urlInfo.pathname;

  const lastSuccessfulApiCall = localStorage.getItem(
    __LAST_SUCCESSFUL_API_CALL_MADE__
  );

  useInterval(() => {
    if (
      !lastApiCallValid(
        new Date(lastSuccessfulApiCall),
        window["__media_dash_config__"]["API_EXPIRATION_TIME"]
      )
    ) {
      localStorage.removeItem(__MEDIA_DASH_ASSERT_ID__);
      window.location.href = configVars["OKTA_URL"];
    }
  }, 1000);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const [sectionOffsetsFromTop, setSectionOffsetsFromTop] = useState({});

  const checkIfVisible = () => {
    const offsetTops = {};
    document.querySelectorAll(".section-style").forEach(element => {
      var position = element.offsetTop - 60;
      offsetTops[element.id] = position;
    });
    setSectionOffsetsFromTop(offsetTops);
  };

  useEffect(() => {
    checkIfVisible();
    window.addEventListener("scroll", checkIfVisible);
  }, []);

  const isTabActive = (tabSection, nextTabSection) => {
    if (
      window.pageYOffset >= sectionOffsetsFromTop[tabSection] &&
      (!nextTabSection ||
        window.pageYOffset < sectionOffsetsFromTop[nextTabSection])
    ) {
      return "active";
    }
    return "";
  };

  return (
    <div className="navbar-container">
      <Navbar bg="" expand="lg" variant="dark" fixed="top">
        <Container>
          <ChannelDropdown />
          <Navbar.Brand
            onClick={() => {
              Analytics.track("Home Logo Click", "Go to Homepage");
              history.push("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              className="quotient-logo"
              alt="Quotient"
              src={"/images/logo.png"}
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              {windowPath === "/" ? (
                <>
                  <AnchorLink
                    className={`nav-link ${isTabActive("topline", "channel")}`}
                    href="#topline"
                  >
                    Topline
                  </AnchorLink>
                  <AnchorLink
                    className={`nav-link ${isTabActive("channel", "retailer")}`}
                    href="#channel"
                  >
                    Channel
                  </AnchorLink>
                  <AnchorLink
                    className={`nav-link ${isTabActive("retailer", "account")}`}
                    href="#retailer"
                  >
                    Retailer
                  </AnchorLink>
                  <AnchorLink
                    className={`nav-link ${isTabActive("account")}`}
                    href="#account"
                  >
                    Account
                  </AnchorLink>
                </>
              ) : (
                ""
              )}
              <Nav.Link className="datepicker-mobile">
                <DateRangeDropdown
                  dateRangeHandler={dateRangeHandler}
                  activeDateRange={activeDateRange}
                  ranges={ranges}
                />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div className="datepicker-desktop">
            <DateRangeDropdown
              dateRangeHandler={dateRangeHandler}
              activeDateRange={activeDateRange}
              ranges={ranges}
            />
          </div>
        </Container>
      </Navbar>
      {window["__media_dash_config__"]["TIMESTAMP_LABEL_TEXT"] &&
        <Container id="timestamp-container">
          <div>
              {window["__media_dash_config__"]["TIMESTAMP_LABEL_TEXT"]}
          </div>
        </Container>
      }
    </div>
  );
}

export default withRouter(Navmenu);
