import React from "react";
import NumberFormat from "react-number-format";
import moment from "moment";

export const numberFormatter = ({
  type,
  val,
  displayType,
  thousandSeparator,
  prefix,
  suffix,
  extraClass,
  noDigitsAfterDecimal,
  digitsAfterDecimal,
}) => {
  switch (type) {
    case "number":
      if (typeof val == "string") {
        return val;
      }
      return (
        <NumberFormat
          className={extraClass}
          value={
            noDigitsAfterDecimal
              ? Math.round(parseFloat(val))
              : typeof val != undefined && digitsAfterDecimal
              ? parseFloat(val).toFixed(digitsAfterDecimal)
              : parseFloat(val).toFixed(2)
          }
          displayType={displayType ? displayType : "text"}
          thousandSeparator={thousandSeparator}
          prefix={prefix}
          suffix={suffix}
        />
      );
    case "object":
      // code block
      return noDigitsAfterDecimal
        ? val
        : val && digitsAfterDecimal
        ? val.toFixed(digitsAfterDecimal)
        : val
        ? val.toFixed(2)
        : val;

    default:
    // code block
  }
};

export const colCalc = (rowVals, colKey, condition) => {
  switch (condition) {
    case "sum":
      return rowVals.reduce((sum, row) => row.values[colKey] + sum, 0);
    case "average":
      const totalRecords = rowVals && rowVals.length;
      const total = rowVals.reduce((sum, row) => row.values[colKey] + sum, 0);
      return total / totalRecords;
    default:
  }
};

export const getQuarterInfo = () => {
  const currentDate = new Date();

  const quarterInfo = () => {
    let quarters = {};
    for (let i = 1; i < 5; i++) {
      if (moment().quarter(i).startOf("quarter")._d > currentDate) {
        const label =
          "Q" +
          i +
          " " +
          moment().subtract(1, "year").quarter(i).startOf("quarter").year();
        quarters[label] = [
          moment().subtract(1, "year").quarter(i).startOf("quarter"),
          moment().subtract(1, "year").quarter(i).endOf("quarter"),
        ];
      } else {
        const label =
          "Q" + i + " " + moment().quarter(i).startOf("quarter").year();
        quarters[label] = [
          moment().quarter(i).startOf("quarter"),
          moment().quarter(i).endOf("quarter"),
        ];
      }
    }
    return quarters;
  };
  const quartersInfo = quarterInfo();

  var descendingQuarters = {};

  [0, 1].map((i) => {
    const quart = moment().subtract(i, "Q").format("[Q]Q Y");
    if (i === 0) {
      return (descendingQuarters["Current Quarter"] = quartersInfo[quart]);
    } else {
      return (descendingQuarters[quart] = quartersInfo[quart]);
    }
  });

  return descendingQuarters || {};
};

export const getWindowQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair && pair.length && decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
};

export const lastApiCallValid = (date1, date2) => {
  const newDate = moment(new Date());
  const lastApiCalledDate = moment(date1);
  const duration = moment.duration(newDate.diff(lastApiCalledDate));
  const hours = duration._data.hours;
  const minutes = duration._data.minutes;

  let res = true;
  if (date2) {
    if (typeof date2.h !== undefined || typeof date2.m !== undefined) {
      if (hours > date2.h) {
        res = false;
      } else if (hours === date2.h && date2.m) {
        if (minutes >= date2.m) {
          res = false;
        }
      }
    }
  }
  return res;
};

export const hideEleFromDomBySelector = (selector) => {
  const ele = document.querySelector(selector);

  if (ele) {
    ele.style.visibility = "hidden";
  }
};

export const showEleFromDomBySelector = (selector) => {
  const ele = document.querySelector(selector);

  if (ele) {
    ele.style.visibility = "visible";
  }
};

export const nFormatter = (num, digits, config) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  if (si[i].value === 1 && config && config.isRevenue) {
    let num2 = num > 0 ? 0.1 : 0;
    if (num > 99 && num < 950) num2 = num * 0.001;
    else if (num >= 950) return "1K";

    return num2.toFixed(digits).substring(1) + "K";
  }
  return (num / si[i].value).toFixed(digits) + si[i].symbol;
};

export const normalizeDataForRetailerTreeGrid = (gridData) => {
  return gridData.reduce((accumulator, res) => {
    res["gridParentCol"] = res.retailerType || res.retailer;
    if (res.retailers || res.retailerTypes) {
      res.subRows = res.retailers || res.retailerTypes;
      res.subRows.forEach((subRow) => {
        if (subRow["retailer"]) {
          subRow["gridParentCol"] = subRow["retailer"];
          subRow["isNotParent"] = true;
        }
      });
    }
    if (!res.isNotParent) {
      accumulator = accumulator.concat(res);
    }
    if (res.subRows) {
      normalizeDataForRetailerTreeGrid(res.subRows);
    }
    return accumulator;
  }, []);
};

export const getExpandedConfigForTreeGrid = (arrayOfObject, leafIdentifier) => {
  let height = arrayOfObject.length;
  function flatten(arrayOfObject, parentIndex, isChild) {
    return arrayOfObject.reduce((accumulator, res, index) => {
      if (
        res.subRows &&
        res.subRows.length > 0 &&
        !res.subRows[0][leafIdentifier]
      ) {
        res.index =
          parentIndex !== undefined ? parentIndex + "." + index : index;
      }

      if (isChild && !res.isNotParent) {
        height = parseInt(height) + 1;
      }

      accumulator = accumulator.concat(res);
      if (res.subRows) {
        accumulator = accumulator.concat(
          flatten(res.subRows, res.index !== undefined ? res.index : 0, true)
        );
        res.subRows = [];
      }
      return accumulator;
    }, []);
  }

  const flattenObj = flatten(arrayOfObject);
  let configObj = {};
  flattenObj.forEach((res) => {
    if (res["index"]) {
      configObj[res["index"]] = true;
    }
  });

  return { configObj: configObj, height: height };
};

export const flattenArrayOfObject = (arrayOfObject) => {
  return arrayOfObject.reduce((accumulator, res) => {
    accumulator = accumulator.concat(res);
    if (res.subRows) {
      accumulator = accumulator.concat(flattenArrayOfObject(res.subRows));
    }
    return accumulator;
  }, []);
};
