import React from "react";
import RetailerGrid from "./RetailerGrid";
import { Container } from "react-bootstrap";

export default function Retailer({ sectionHeading, activeDateRange }) {
  return (
    <div>
      <div className="white-space"></div>
      <div id="retailer" className="section-style gray">
        <Container className="topline-section-container">
          <div>
            <h1 className="text-center heading">{sectionHeading}</h1>
          </div>
          <RetailerGrid activeDateRange={activeDateRange} />
        </Container>
      </div>
    </div>
  );
}
