import React from "react";
import { Link } from "react-router-dom";
import * as Analytics from "../components/common/Analytics";
function AnchorSmoothScrollLink(props) {
  const smoothScroller = e => {
    e.preventDefault();

    let offset = () => 0;
    if (typeof props.offset !== "undefined") {
      if (!!(props.offset && props.offset.constructor && props.offset.apply)) {
        offset = props.offset;
      } else {
        offset = () => parseInt(props.offset);
      }
    }
    let id = e.currentTarget.getAttribute("href").slice(1);
    id = id && id.replace("#", "");
    const $anchor = document.getElementById(id);
    if ($anchor) {
      const navLinks = document.querySelectorAll(".nav-link");
      navLinks.forEach(link => {
        link.classList.remove("active");
      });
      e.target.classList.add("active");
      const offsetTop =
        $anchor.getBoundingClientRect().top + window.pageYOffset;
      window.scroll({
        top: offsetTop - offset() - 55,
        behavior: "smooth"
      });
    }
    if (props.onClick) {
      props.onClick(e);
    }
    Analytics.track("Scroll to " + id, "Nav Click");
  };

  const { offset, ...rest } = props;
  return <Link to={props.href} {...rest} onClick={smoothScroller} />;
}

export default AnchorSmoothScrollLink;
