import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import * as Analytics from "../common/Analytics";
const configVars = {
  DEFAULT_DATE_RANGE:
    window["__media_dash_config__"] &&
    window["__media_dash_config__"]["DEFAULT_DATE_RANGE"]
};
export default function DateRangeDropdown({
  extraClass,
  dateRangeHandler,
  activeDateRange,
  ranges
}) {
  const adjustQuarterToDateRange = dateRange => {
    const endDate = dateRange.endDate;
    const actualDate = moment(endDate, "MM/DD/YYYY").subtract(1, "days");
    return actualDate.format("MM/DD/YYYY") || "";
  };
  const onDateRangeChange = (o, dateRangeObject) => {
    const chosenLabel = dateRangeObject.chosenLabel;
    const apiRangeVal = [
      "month_todate",
      "quarter_todate",
      "yesterday",
      "last_month",
      "current_month",
      "current_quarter",
      "last_quarter",
      "secondlast_quarter",
      "thirdlast_quarter"
    ];
    let range = configVars["DEFAULT_DATE_RANGE"]
      ? configVars["DEFAULT_DATE_RANGE"]
      : apiRangeVal[4];

    const ranges = dateRangeObject.ranges;
    for (let key in ranges) {
      if (key === chosenLabel) {
        range = apiRangeVal[Object.keys(ranges).indexOf(key)];
      }
    }

    var newDateObj = {
      startDate: moment(
        dateRangeObject &&
          dateRangeObject.startDate &&
          dateRangeObject.startDate._d
      ).format("L"),
      endDate: moment(
        dateRangeObject &&
          dateRangeObject.startDate &&
          dateRangeObject.endDate._d
      ).format("L"),
      rangeValue: dateRangeObject.chosenLabel,
      range: range
    };
    Analytics.track("Click " + dateRangeObject.chosenLabel + " Daterange", "Daterange Dropdown Selection");
    dateRangeHandler(newDateObj);
  };

  return (
    <DateRangePicker
      onApply={onDateRangeChange}
      ranges={ranges}
      startDate={activeDateRange.startDate}
      endDate={activeDateRange.endDate}
      bsPrefix="d-none d-lg-block"
    >
      <div className={extraClass}>
        <span className="text">
          {activeDateRange.rangeValue}
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ marginLeft: "5px" }}
          ></FontAwesomeIcon>
        </span>
        <br />
        <span className="dates">
          {activeDateRange.startDate} -{" "}
          {activeDateRange.range === "quarter_todate"
            ? adjustQuarterToDateRange(activeDateRange)
            : activeDateRange.endDate}
        </span>
      </div>
    </DateRangePicker>
  );
}
