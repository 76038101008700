import React, { useState, useEffect } from "react";
import { Spinner, Container } from "react-bootstrap";
import GridGenerator from "../../generator/grid/GridGenerator";
import { numberFormatter } from "../../../utils/Helper";
import { Form, Col, InputGroup } from "react-bootstrap";

import { makeApiRequest } from "../../../utils/ApiHelper";
import { withRouter } from "react-router-dom";
import { CAMPAIGN_PERFORMANCE_ENDPOINT } from "../../../EndPoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { __CHANNEL_GRID_TOOLTIP_INFO__ } from "../../../constants/Constants";
import * as Analytics from "../../common/Analytics";

function CampaignPerformanceGrid({ match, activeDateRange }) {
  const [
    campaignPerformanceSummaryData,
    setCampaignPerformanceSummaryData
  ] = useState({});

  const [campaignPerformanceData, setCampaignPerformanceData] = useState([]);
  const [allCampaignPerformanceData, setAllCampaignPerformanceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState({ loading: true });
  const [loadingPaginatedData, setLoadingPaginatedData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const onLoadMoreButtonClicked = () => {
    Analytics.track("Load More button clicked", "Campaign Performance grid load more");
    const nextPage = currentPage + 1;
    if (nextPage > totalPages) {
      return;
    }
    setLoadingPaginatedData(true);
    setCurrentPage(nextPage);
  };

  const onSearchButtonClicked = e => {
    const searchText = document.getElementById("campaignPerformanceFilter")
      .value;
    setSearchQuery(searchText);
    setCurrentPage(1);
    Analytics.track("Search for: " + searchText, "Campaign Performance grid search");
    getAndloadCampaignPerformanceGridData({
      searchQuery: searchText,
      pageIndex: 1
    });
    return false;
  };

  const getAndloadCampaignPerformanceGridData = ({
    pageIndex,
    searchQuery
  }) => {
    const specificChannel = match && match.params && match.params.id;
    const currentRange = activeDateRange && activeDateRange.range;
    let pageQuery = currentPage;

    if (pageIndex) {
      pageQuery = pageIndex;
    }
    let url =
      CAMPAIGN_PERFORMANCE_ENDPOINT +
      "?range=" +
      currentRange +
      "&channel=" +
      specificChannel +
      "&pageNum=" +
      pageQuery;
    if (searchQuery) {
      url += "&filterQuery=" + searchQuery;
    }
    makeApiRequest(url, "get", {
      cmpId: ["campaign-performance-grid"],
      setIsLoading: setIsLoading,
      hideFullLoadingSpinner: currentPage > 1 && !pageIndex ? true : false
    }).then(data => {
      const allCampaigns = data && data.data && data.data.allCampaigns;
      data = data && data.data && data.data.data;
      setCampaignPerformanceSummaryData(data.summary);
      setTotalPages(data.pageQuery.totalPages);
      if (currentPage == 1 || pageIndex == 1) {
        setCampaignPerformanceData(data.campaigns);
        setAllCampaignPerformanceData(allCampaigns);
      } else {
        setCampaignPerformanceData(prevState => {
          return [...prevState, ...data.campaigns];
        });
      }
      setLoadingPaginatedData(false);
      setIsLoading({ loading: false });
    });
  };

  useEffect(() => {
    setCurrentPage(1);
    setSearchQuery("");
    getAndloadCampaignPerformanceGridData({ pageIndex: 1 });
  }, [activeDateRange, match]);

  useEffect(() => {
    if (currentPage != 1) getAndloadCampaignPerformanceGridData({});
  }, [currentPage]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Account",
        accessor: "account",
        minWidth: 250,
        tdClassName: "black-text text-left"
      },
      {
        Header: "Io Name",
        accessor: "ioName",
        minWidth: 250
      },
      {
        Header: "Opportunity",
        accessor: "opportunityNumber",
        minWidth: 140
      },
      {
        Header: "Impressions",
        accessor: "impressionsDelivered",
        tdClassName: "text-right",
        minWidth: 150,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.impressionsDelivered,
              thousandSeparator: true,
              noDigitsAfterDecimal: true
            });
          }
        }
      },
      {
        Header: "Booked Revenue",
        accessor: "revenuePlanned",
        minWidth: 120,
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.revenuePlanned,
              thousandSeparator: true,
              prefix: "$",
              noDigitsAfterDecimal: true
            });
          }
        }
      },
      {
        Header: "Rev. Delivered",
        accessor: "revenueDelivered",
        minWidth: 120,
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.revenueDelivered,
              thousandSeparator: true,
              prefix: "$",
              noDigitsAfterDecimal: true
            });
          }
        }
      },
      {
        Header: "Margin",
        tdClassName: "text-right",
        accessor: "marginPercent",
        minWidth: 110,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.marginPercent,
              thousandSeparator: false,
              suffix: "%",
              digitsAfterDecimal: 1
            });
          }
        }
      },
      {
        Header:
          match && match.params && match.params.id === "social_influencer"
            ? "% Content Revenue"
            : "Content Revenue",
        accessor: "contentRevenuePercent",
        minWidth: 120,
        tdClassName: "text-right",
        hide:
          match && match.params && match.params.id === "social_influencer"
            ? false
            : true,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.contentRevenuePercent,
              thousandSeparator: true,
              suffix: "%",
              noDigitsAfterDecimal: true
            });
          }
        }
      },
      {
        Header: "Pace Vs. Booked",
        accessor: "paceBookedPercent",
        minWidth: 110,
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.paceBookedPercent,
              thousandSeparator: true,
              digitsAfterDecimal: 1,
              suffix: "%"
            });
          }
        }
      },

      {
        Header:
          (match && match.params && match.params.id === "social_influencer") ||
          (match && match.params && match.params.id === "social_display")
            ? "Engagement Rate"
            : "FAR",
        accessor: "firstActionRatePercent",
        tdClassName: "text-right",
        minWidth:
          (match && match.params && match.params.id === "social_influencer") ||
          (match && match.params && match.params.id === "social_display")
            ? 140
            : 100,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.firstActionRatePercent,
              thousandSeparator: false,
              suffix: "%",
              digitsAfterDecimal: 2
            });
          }
        }
      },

      {
        Header: "Viewability",
        accessor: "viewablePercent",
        tdClassName: "text-right",
        minWidth: 120,
        hide:
          (match && match.params && match.params.id === "social_influencer") ||
          (match && match.params && match.params.id === "social_display")
            ? true
            : false,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.viewablePercent,
              thousandSeparator: false,
              suffix: "%",
              digitsAfterDecimal: 1
            });
          }
        }
      },

      {
        Header: "Invalid Traffic Rate",
        tdClassName: "text-right",
        accessor: "ivtPercent",
        hide:
          (match && match.params && match.params.id === "social_influencer") ||
          (match && match.params && match.params.id === "social_display")
            ? true
            : false,
        minWidth: 80,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.ivtPercent,
              thousandSeparator: false,
              suffix: "%",
              digitsAfterDecimal: 1
            });
          }
        }
      },
      {
        Header: "revenueCost",
        accessor: "revenueCost",
        hide: true
      },
      {
        Header: "revenueMargin",
        accessor: "revenueMargin",
        hide: true
      }
    ],
    [match, campaignPerformanceData]
  );

  return (
    <div>
      {isLoading.loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <div>
          <Container>
            <Form>
              <Form.Group
                as={Col}
                sm="12"
                md="8"
                controlId="campaignPerformanceFilter"
                style={{ padding: 0 }}
              >
                <InputGroup>
                  <Form.Control
                    type="text"
                    column="true"
                    sm={5}
                    placeholder="Filter"
                    value={searchQuery}
                    onChange={e => {
                      setSearchQuery(e.target.value);
                    }}
                    onKeyPress={event => {
                      if (event.key === "Enter") {
                        onSearchButtonClicked();
                      }
                    }}
                    aria-describedby="campaignPerformanceInputGroupPrepend"
                  />
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="campaignPerformanceinputGroupPrepend"
                      onClick={onSearchButtonClicked}
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control.Feedback type="invalid">
                    Please choose a username.
                  </Form.Control.Feedback>
                  <Form.Label
                    column
                    sm={7}
                    style={{
                      color: "#262d33",
                      opacity: 0.5,
                      fontWeigth: "bold"
                    }}
                  >
                    {campaignPerformanceSummaryData &&
                      campaignPerformanceSummaryData.liveCampaigns}{" "}
                    LIVE,{" "}
                    {campaignPerformanceSummaryData &&
                      campaignPerformanceSummaryData.bookedCampaigns}{" "}
                    SCHEDULED CAMPAIGNS
                  </Form.Label>
                </InputGroup>
              </Form.Group>
            </Form>
          </Container>

          <div className="section-style gray">
            <Container>
              <GridGenerator
                columns={columns}
                data={campaignPerformanceData}
                colMinWidth={100}
                colWidth={100}
                gridHeight={
                  campaignPerformanceData && campaignPerformanceData.length > 12
                    ? 600
                    : campaignPerformanceData.length * 45
                }
                virtualizedRow={true}
                cellHeight={45}
                gridLabel="Campaign Performance Grid"
                gridId="campaign-performance-grid"
                exportGridId="channelfilteredcampaigns"
                hideFooter={true}
                showLoadMoreButton={true}
                onLoadMoreButtonClicked={onLoadMoreButtonClicked}
                currentPageIndex={currentPage}
                totalPages={totalPages}
                paginatedDataLoaded={loadingPaginatedData}
                toolTipInfo={__CHANNEL_GRID_TOOLTIP_INFO__}
                allData={allCampaignPerformanceData}
              />
            </Container>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(CampaignPerformanceGrid);
