import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import GridGenerator from "../generator/grid/GridGenerator";
import { numberFormatter } from "../../utils/Helper";
import { makeApiRequest } from "../../utils/ApiHelper";
import { SPECIFIC_CHANNEL_DSP_COST_BREAKDOWN_ENDPOINT } from "../../EndPoints";
import { showToast } from "../../utils/ApiHelper";
import { withRouter } from "react-router-dom";
import { __DSP_GRID_TOOLTIP_INFO__ } from "../../constants/Constants";

function DspBreakDownGrid({ activeDateRange, match }) {
  const [dspBreakDownData, setDspBreakDownGridData] = useState([]);
  const [dspBreakDownTotals, setDspBreakDownGridDataTotals] = useState({});
  const [isLoading, setIsLoading] = useState({ loading: true });
  useEffect(() => {
    const currentRange = activeDateRange && activeDateRange.range;
    const specificChannel = match && match.params && match.params.id;

    const url =
      SPECIFIC_CHANNEL_DSP_COST_BREAKDOWN_ENDPOINT +
      "?range=" +
      currentRange +
      "&channel=" +
      specificChannel;
    makeApiRequest(url, "get", {
      cmpId: ["account"],
      setIsLoading: setIsLoading
    }).then(data => {
      const apiResponse = data && data.data && data.data.data;
      if (apiResponse) {
        setDspBreakDownGridData(apiResponse.dspdetails);
        setDspBreakDownGridDataTotals(apiResponse.totals);
      } else {
        showToast(
          "error",
          "Oops, there was an issue getting data for the Dsp Breakdown grid. If this message persists please contact your administrator."
        );
      }
      setIsLoading({ loading: false });
    });
  }, [activeDateRange, match]);

  const columns = React.useMemo(
    () => [
      {
        Header: "DSP",
        accessor: "dspName",
        minWidth: 110,
        tdClassName: "black-text text-left",
        Footer: info => {
          return <span className="black-text">Total</span>;
        }
      },
      {
        Header: "DSP Impressions",
        accessor: "impressionsDelivered",
        tdClassName: "text-right",
        minWidth: 170,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.impressionsDelivered,
              thousandSeparator: true,
              noDigitsAfterDecimal: true
            });
          }
        },
        Footer: info => {
          const total = dspBreakDownTotals["impressionsDelivered"];
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: true,
                noDigitsAfterDecimal: true
              })}
            </span>
          );
        }
      },
      {
        Header: "Media Spend",
        accessor: "mediaCost",
        tdClassName: "text-right",
        minWidth: 150,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.mediaCost,
              thousandSeparator: true,
              noDigitsAfterDecimal: true,
              prefix: "$"
            });
          }
        },
        Footer: info => {
          const total = dspBreakDownTotals["mediaCost"];
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: true,
                noDigitsAfterDecimal: true,
                prefix: "$"
              })}
            </span>
          );
        }
      },
      {
        Header: "Media Buy Rate",
        accessor: "avgMediaBuyRate",
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.avgMediaBuyRate,
              thousandSeparator: true,
              prefix: "$"
            });
          }
        },
        Footer: info => {
          const total = dspBreakDownTotals["avgMediaBuyRate"];

          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: true,
                prefix: "$"
              })}
            </span>
          );
        }
      },
      {
        Header: "Data Cost",
        accessor: "dataCost",
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.dataCost,
              thousandSeparator: true,
              prefix: "$",
              noDigitsAfterDecimal: true
            });
          }
        },
        Footer: info => {
          const total = dspBreakDownTotals["dataCost"];

          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: true,
                prefix: "$",
                noDigitsAfterDecimal: true
              })}
            </span>
          );
        }
      },
      {
        Header: "Data Cost CPM",
        accessor: "dataCostCPM",
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.dataCostCPM,
              thousandSeparator: true,
              prefix: "$",
              digitsAfterDecimal: 2
            });
          }
        },
        Footer: info => {
          const total = dspBreakDownTotals["dataCostCPM"];

          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: true,
                prefix: "$",
                digitsAfterDecimal: 2
              })}
            </span>
          );
        }
      },
      {
        Header: "Total DSP Cost",
        accessor: "totalDSPCost",
        tdClassName: "text-right",
        minWidth: 140,
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.totalDSPCost,
              thousandSeparator: true,
              prefix: "$",
              digitsAfterDecimal: 2
            });
          }
        },
        Footer: info => {
          const total = dspBreakDownTotals["totalDSPCost"];

          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: true,
                prefix: "$",
                digitsAfterDecimal: 2
              })}
            </span>
          );
        }
      },
      {
        Header: "% of Total Impressions",
        accessor: "totalPercent",
        minWidth: 130,
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.totalPercent,
              thousandSeparator: false,
              suffix: "%",
              digitsAfterDecimal: 1
            });
          }
        },
        Footer: info => {
          const total = dspBreakDownTotals["totalPercent"];

          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: false,
                suffix: "%",
                digitsAfterDecimal: 1
              })}
            </span>
          );
        }
      },
      {
        Header: "% of DSP Cost",
        accessor: "totalPercentDSPCost",
        minWidth: 130,
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.totalPercentDSPCost,
              thousandSeparator: false,
              suffix: "%",
              digitsAfterDecimal: 1
            });
          }
        },
        Footer: info => {
          const total = dspBreakDownTotals["totalPercentDSPCost"];

          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: total,
                thousandSeparator: false,
                suffix: "%",
                digitsAfterDecimal: 1
              })}
            </span>
          );
        }
      }
    ],
    [dspBreakDownTotals]
  );

  return (
    <div>
      {isLoading.loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <GridGenerator
            columns={columns}
            data={dspBreakDownData}
            colMinWidth={100}
            colWidth={100}
            gridHeight={
              dspBreakDownData && dspBreakDownData.length > 8
                ? 550
                : dspBreakDownData.length * 45
            }
            virtualizedRow={true}
            cellHeight={45}
            gridLabel="DSP Breakdown Grid"
            gridId="dsp-breakdown-grid"
            exportGridId="channelfilteredcostdsp"
            toolTipInfo={__DSP_GRID_TOOLTIP_INFO__}
            totals={dspBreakDownTotals}
          />
          <small> * Ubimo data costs included post 6/1/20</small>
        </>
      )}
    </div>
  );
}

export default withRouter(DspBreakDownGrid);
