import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import { makeApiRequest } from "../../utils/ApiHelper";
import { SPECIFIC_CHANNEL_ENDPOINT } from "../../EndPoints";
import OverallMetricsGenerator from "../generator/general/OverallMetricsGenerator";

function ToplineSpecificChannel({ specificChannel, activeDateRange }) {
  const [specificChannelData, setSpecificChannelData] = useState([]);
  const [isLoading, setIsLoading] = useState({ loading: true });

  useEffect(() => {
    const currentRange = activeDateRange && activeDateRange.range;
    const url =
      SPECIFIC_CHANNEL_ENDPOINT +
      "?range=" +
      currentRange +
      "&channel=" +
      specificChannel;
    makeApiRequest(url, "get", {
      cmpId: ["specific-channel-topline"],
      setIsLoading: setIsLoading
    }).then(res => {
      if (res.data && res.data.data) {
        setSpecificChannelData(res.data.data);
      }
      setIsLoading({ loading: false });
    });
  }, [activeDateRange, specificChannel]);

  const objMapper = [
    {
      colSize: 3,
      apiKey: "impressionsDelivered",
      label: "Impressions",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      noDigitsAfterDecimal: true
      // child: {
      //   apiKey: "",
      //   appendToVal: "OSI",
      //   type: "number",
      //   displayType: "text",
      //   thousandSeparator: true,
      //   suffix: "%",
      //   digitsAfterDecimal: 1
      // }
    },
    {
      colSize: 3,
      apiKey: "revenueDelivered",
      label: "Est. Revenue Delivered",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      prefix: "$",
      noDigitsAfterDecimal: true
    },
    {
      colSize: 3,
      apiKey: "revenuePlanned",
      label: "Booked Revenue",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      noDigitsAfterDecimal: true,
      prefix: "$",
      child: {
        apiKey: "paceBookedPercent",
        appendToVal: "Delivered vs. Booked",
        type: "number",
        displayType: "text",
        thousandSeparator: true,
        suffix: "%",
        digitsAfterDecimal: 1
      }
    },
    {
      colSize: 3,
      apiKey: {
        apiKey: ["liveCampaigns", "bookedCampaigns"],
        separator: " / "
      },
      label: "Live/Booked Campaigns",
      type: "object",
      displayType: "text",
      noDigitsAfterDecimal: true,
      child: {
        appendToVal: "",
        apiKey: "liveBookedCampaignsPercent",
        type: "number",
        displayType: "text",
        thousandSeparator: true,
        suffix: "%",
        digitsAfterDecimal: 1
      }
    },
    {
      colSize: 3,
      apiKey: "avgSaleRate",
      label: "Avg. Sell Rate",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      prefix: "$"
    },
    {
      colSize: 3,
      apiKey: "avgMediaBuyRate",
      label: "Avg. Media Buy Rate",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      prefix: "$"
    },
    {
      colSize: 3,
      apiKey: "revenueMargin",
      label: "Margin",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      noDigitsAfterDecimal: true,
      prefix: "$",
      child: {
        apiKey: "marginPercent",
        type: "number",
        displayType: "text",
        thousandSeparator: true,
        suffix: "%",
        digitsAfterDecimal: 1
      }
    },
    {
      colSize: 3,
      apiKey: "dataCostRevenuePercent",
      label: "Data Cost as % of Revenue",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      suffix: "%",
      digitsAfterDecimal: 1
    }
  ];
  return (
    <div id="specific-channel-topline" className="section-style">
      <Container className="topline-section-container">
        <div>
          <h1 className="text-center heading">{specificChannelData.channel}</h1>
        </div>
        {isLoading.loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <div>
            <OverallMetricsGenerator
              objMapper={objMapper}
              data={specificChannelData.toplinemetrics}
            />
          </div>
        )}
      </Container>
    </div>
  );
}

export default ToplineSpecificChannel;
