import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import GridGenerator from "../../generator/grid/GridGenerator";
import {
  numberFormatter,
  normalizeDataForRetailerTreeGrid,
  getExpandedConfigForTreeGrid
} from "../../../utils/Helper";
import { makeApiRequest } from "../../../utils/ApiHelper";
import { RETAILER_ENDPOINT } from "../../../EndPoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { __CHANNEL_GRID_TOOLTIP_INFO__ } from "../../../constants/Constants";

export default function ChannelGrid({ activeDateRange }) {
  const [retailerGridData, setRetailerGridData] = useState([]);
  const [retailerGridTotalData, setRetailerGridTotalData] = useState([]);
  const [isLoading, setIsLoading] = useState({ loading: true });
  const [expandedConfig, setExpandedConfig] = useState({});
  const [gridHeight, setGridHeight] = useState(0);
  useEffect(() => {
    const currentRange = activeDateRange && activeDateRange.range;
    const url = RETAILER_ENDPOINT + "?range=" + currentRange;
    makeApiRequest(url, "get", {
      cmpId: ["retailer"],
      setIsLoading: setIsLoading
    }).then(res => {
      const gridData =
        (res && res.data && res.data.data && res.data.data.retailerTypes) || [];
      const gridTotal =
        res && res.data && res.data.data && res.data.data.totals;
      let normalizeData = normalizeDataForRetailerTreeGrid(gridData);
      setRetailerGridData(normalizeData);
      setRetailerGridTotalData(gridTotal);
      let copyOfNormalizeData = JSON.parse(JSON.stringify(normalizeData));
      const expandedConfigObject = getExpandedConfigForTreeGrid(
        copyOfNormalizeData,
        "retailer"
      );
      setExpandedConfig(expandedConfigObject["configObj"]);
      setGridHeight(expandedConfigObject["height"] * 45);
      setIsLoading({ loading: false });
    });
  }, [activeDateRange]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Retailer",
        accessor: "gridParentCol",
        tdClassName: "text-left",
        restrictReOrder: true,
        minWidth: 200,
        Cell: ({ row }) => {
          return row.canExpand ? (
            <div
              className="tree-grid-row"
              {...row.getExpandedToggleProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`
                }
              })}
            >
              {row.values.gridParentCol}
              {row.isExpanded ? (
                <FontAwesomeIcon
                  className="tree-expand-collapse-icon"
                  icon={faAngleDown}
                ></FontAwesomeIcon>
              ) : (
                <FontAwesomeIcon
                  className="tree-expand-collapse-icon"
                  icon={faAngleRight}
                ></FontAwesomeIcon>
              )}
            </div>
          ) : (
            <div
              className="tree-grid-row"
              style={{
                paddingLeft: `${row.depth * 2}rem`
              }}
            >
              {row.values.gridParentCol}
            </div>
          );
        },
        Footer: info => {
          return <span className="black-text">Total</span>;
        }
      },
      {
        Header: "Est. Revenue Delivered",
        accessor: "revenueDelivered",
        minWidth: 150,
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.revenueDelivered,
              prefix: "$",
              noDigitsAfterDecimal: true,
              thousandSeparator: true
            });
          }
        },
        Footer: info => {
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: retailerGridTotalData.revenueDelivered,
                thousandSeparator: true,
                noDigitsAfterDecimal: true,
                prefix: "$"
              })}
            </span>
          );
        }
      },
      {
        Header: "Impressions",
        tdClassName: "text-right",
        minWidth: 150,
        accessor: "impressionsDelivered",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.impressionsDelivered,
              thousandSeparator: true,
              noDigitsAfterDecimal: true
            });
          }
        },
        Footer: info => {
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: retailerGridTotalData.impressionsDelivered,
                thousandSeparator: true,
                noDigitsAfterDecimal: true
              })}
            </span>
          );
        }
      },
      {
        Header: "Avg. Sell Rate",
        accessor: "avgSaleRate",
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              type: "number",
              val: row.values.avgSaleRate,
              thousandSeparator: true,
              prefix: "$"
            });
          }
        },
        Footer: info => {
          return (
            <span className="black-text">
              {numberFormatter({
                type: "number",
                val: retailerGridTotalData.avgSaleRate,
                thousandSeparator: true,
                prefix: "$"
              })}
            </span>
          );
        }
      },
      {
        Header: "Margin",
        accessor: "marginPercent",
        tdClassName: "text-right",
        Cell: ({ row }) => {
          if (row && row.values) {
            return numberFormatter({
              digitsAfterDecimal: 1,
              type: "number",
              val: row.values.marginPercent,
              suffix: "%"
            });
          }
        },
        Footer: info => {
          return (
            <span className="black-text">
              {numberFormatter({
                digitsAfterDecimal: 1,
                type: "number",
                val: retailerGridTotalData.marginPercent,
                thousandSeparator: true,
                suffix: "%"
              })}
            </span>
          );
        }
      },
      {
        Header: "revenuePlanned",
        accessor: "revenuePlanned",
        hide: true
      },
      {
        Header: "impressionsPlanned",
        accessor: "impressionsPlanned",
        hide: true
      }
    ],
    [retailerGridTotalData]
  );

  return (
    <div>
      {isLoading.loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <GridGenerator
            columns={columns}
            data={retailerGridData}
            colMinWidth={100}
            colWidth={100}
            gridHeight={gridHeight && gridHeight > 450 ? 450 : gridHeight}
            // gridHeight={
            //   retailerGridData && retailerGridData.length > 10
            //     ? 500
            //     : expandedConfig
            //     ? Object.keys(expandedConfig).length > retailerGridData.length
            //       ? Object.keys(expandedConfig).length * 50
            //       : retailerGridData.length * 50
            //     : retailerGridData.length * 50
            // }
            gridType="tree"
            virtualizedRow={true}
            cellHeight={45}
            gridLabel="Retailer Grid"
            gridId="retailer-grid"
            exportGridId="retailersmetrics"
            expandedConfig={expandedConfig}
            toolTipInfo={__CHANNEL_GRID_TOOLTIP_INFO__}
            totals={retailerGridTotalData}
          />
          <small>* Excludes Elevaate data</small>
        </>
      )}
    </div>
  );
}
