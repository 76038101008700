import React from "react";
import { lastApiCallValid } from "../../utils/Helper";
import {
  __MEDIA_DASH_ASSERT_ID__,
  __LAST_SUCCESSFUL_API_CALL_MADE__
} from "../../constants/Constants";
import { getWindowQueryVariable } from "../../utils/Helper";
import { makeApiRequest } from "../../utils/ApiHelper";
import { USER_INFO_ENDPOINT } from "../../EndPoints";
import { Spinner } from "react-bootstrap";
import LogRocket from "logrocket";

const configVars = {
  OKTA_URL:
    window["__media_dash_config__"] &&
    window["__media_dash_config__"]["OKTA_URL"]
};

export default function Auth({ handleAuth }) {
  //Get assert id from url
  let assertId = getWindowQueryVariable("id");
  if (assertId) {
    //this means user is coming from okta --> update localstorage vars
    localStorage.setItem(__LAST_SUCCESSFUL_API_CALL_MADE__, new Date());
    window.history.pushState({}, document.title, "/");
  }

  const assertIdFromLocalStorage = localStorage.getItem(
    __MEDIA_DASH_ASSERT_ID__
  );
  const lastSuccessfulApiCall = localStorage.getItem(
    __LAST_SUCCESSFUL_API_CALL_MADE__
  );

  assertId = assertId ? assertId : assertIdFromLocalStorage;
  if (
    !assertId ||
    assertId === "" ||
    !lastApiCallValid(
      new Date(lastSuccessfulApiCall),
      window["__media_dash_config__"]["API_EXPIRATION_TIME"]
    )
  ) {
    //expiration logic
    localStorage.removeItem(__MEDIA_DASH_ASSERT_ID__);
    window.location.href = configVars["OKTA_URL"];
  } else if (assertId && assertId !== "") {
    localStorage.setItem(__MEDIA_DASH_ASSERT_ID__, assertId);
    makeApiRequest(USER_INFO_ENDPOINT, "get").then(data => {
      if (data && data.data && data.data.status === "success") {
        data = data.data;
        if (
          window["__media_dash_config__"]["logrocketRunUrls"].indexOf(
            window.location.host
          ) > -1
        ) {
          LogRocket.identify(data.email, {
            name: data.email,
            email: data.email,
            subscriptionType: "pro"
          });
        }
        window["__media_dash_config__"]["user_email"] = data.email;
        handleAuth(true);
      } else {
        localStorage.removeItem(__MEDIA_DASH_ASSERT_ID__);
        window.location.href = configVars["OKTA_URL"];
      }
    });
  } else {
    //fallback to okta dashboard
    localStorage.removeItem(__MEDIA_DASH_ASSERT_ID__);
    window.location.href = configVars["OKTA_URL"];
  }

  return (
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
}
