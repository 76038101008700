import React, { useContext } from "react";
import Topline from "./topline/Topline";
import Channel from "./channel/Channel";
import Account from "./account/Account";
import Retailer from "./retailer/Retailer";
import { OverallChannelMetricsContextProvider } from "../../contexts/home/OverallChannelMetricsContext";
import { DateRangeContext } from "../../contexts/common/DateRangeContext";

import { defaults } from "react-chartjs-2";
import variables from "../../sass/_default-variable.scss";
defaults.global.defaultFontFamily = variables.font2;

export default function Home() {
  const { activeDateRange } = useContext(DateRangeContext);
  return (
    <div>
      <OverallChannelMetricsContextProvider activeDateRange={activeDateRange}>
        <Topline sectionHeading={"Topline"} />
        <Channel sectionHeading={"Channel"} activeDateRange={activeDateRange} />
      </OverallChannelMetricsContextProvider>
      <Retailer sectionHeading={"Retailer"} activeDateRange={activeDateRange} />
      
      <Account sectionHeading={"Account"} activeDateRange={activeDateRange} />
    </div>
  );
}
