import axios from "axios";
import {
  __LAST_SUCCESSFUL_API_CALL_MADE__,
  __MEDIA_DASH_ASSERT_ID__
} from "../constants/Constants";
import { showToast, handleApiError } from "./ApiHelper";
import { hideEleFromDomBySelector, showEleFromDomBySelector } from "./Helper";
axios.create();

axios.defaults.baseURL = window.location.origin;

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (
      config.extraParams &&
      config.extraParams.setIsLoading &&
      !config.extraParams.hideFullLoadingSpinner
    ) {
      config.extraParams.setIsLoading({ loading: true });
    }
    if (config.extraParams && config.extraParams.cmpId) {
      const cmpId = config.extraParams.cmpId;
      if (cmpId) {
        if (Array.isArray(cmpId)) {
          cmpId.forEach(id => {
            showEleFromDomBySelector("#" + id + " .spinner-border");
          });
        } else {
          showEleFromDomBySelector("#" + cmpId + " .spinner-border");
        }
      }
    }
    config["headers"] = {
      ...config["headers"],
      ...{
        authentication: `${localStorage.getItem(__MEDIA_DASH_ASSERT_ID__)}`
      }
    };
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    const status = response && response.data && response.data.status;
    if (status && status === "error") {
      handleApiError(response);
      return Promise.reject(response);
    }

    // Do something with response data
    localStorage.setItem(__LAST_SUCCESSFUL_API_CALL_MADE__, new Date());
    return response;
  },
  error => {
    showToast("error", error.toString());
    const cmpId =
      error.config &&
      error.config.extraParams &&
      error.config.extraParams.cmpId;

    if (cmpId) {
      if (Array.isArray(cmpId)) {
        cmpId.forEach(id => {
          hideEleFromDomBySelector("#" + id + " .spinner-border");
        });
      } else {
        hideEleFromDomBySelector("#" + cmpId + " .spinner-border");
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axios;
