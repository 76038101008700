import React, { useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import { OverallChannelMetricsContext } from "../../../contexts/home/OverallChannelMetricsContext";
import OverallMetricsGenerator from "../../generator/general/OverallMetricsGenerator";

export default function Topline({ sectionHeading }) {
  const { topLineMetricsData, isLoading } = useContext(
    OverallChannelMetricsContext
  );
  const objMapper = [
    {
      apiKey: "revenueDelivered",
      label: "Est. Revenue Delivered",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      noDigitsAfterDecimal: true,
      prefix: "$",
      colSize: 4,
      child: {
        apiKey: "paceBookedPercent",
        appendToVal: "Delivered  vs. Booked",
        type: "number",
        displayType: "text",
        thousandSeparator: true,
        suffix: "%",
        digitsAfterDecimal: 1
      }
    },
    {
      apiKey: "revenueForecasted",
      label: "Revenue Goal",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      noDigitsAfterDecimal: true,
      prefix: "$",
      colSize: 4,
      child: {
        apiKey: "paceForeCastPercent",
        appendToVal: "Delivered  Vs. Goal",
        type: "number",
        displayType: "text",
        thousandSeparator: true,
        suffix: "%",
        digitsAfterDecimal: 1
      }
    },
    {
      apiKey: "revenuePlanned",
      label: "Booked Revenue",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      noDigitsAfterDecimal: true,
      prefix: "$",
      colSize: 3,
      child: {
        apiKey: "bookedForecastPercent",
        appendToVal: "Booked vs. Goal",
        type: "number",
        displayType: "text",
        thousandSeparator: true,
        suffix: "%",
        digitsAfterDecimal: 1
      }
    },
    {
      apiKey: "impressionsDelivered",
      label: "Impressions",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      noDigitsAfterDecimal: true,
      colSize: 3
    },
    {
      apiKey: "avgSaleRate",
      label: "Avg. Sell Rate",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      prefix: "$",
      colSize: 3
    },
    {
      apiKey: "marginPercent",
      label: "Margin",
      type: "number",
      displayType: "text",
      thousandSeparator: true,
      suffix: "%",
      digitsAfterDecimal: 1,
      colSize: 3
    },
    {
      apiKey: {
        apiKey: ["liveCampaigns", "bookedCampaigns"],
        separator: " / "
      },
      label: "Live/Booked Campaigns",
      type: "object",
      displayType: "text",
      noDigitsAfterDecimal: true,
      colSize: 3,
      child: {
        apiKey: "liveBookedCampaignsPercent",
        type: "number",
        displayType: "text",
        thousandSeparator: true,
        suffix: "%",
        digitsAfterDecimal: 1
      }
    }
  ];
  return (
    <div id="topline" className="section-style" style={{ paddingTop: "30px" }}>
      <Container className="topline-section-container">
        <div>
          <h1 className="text-center heading no-margin-bottom">
            {sectionHeading}
          </h1>
        </div>
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <div>
            <OverallMetricsGenerator
              objMapper={objMapper}
              data={topLineMetricsData}
            />
          </div>
        )}
      </Container>
    </div>
  );
}
