import React, { useState, useContext } from "react";

import {
  useTable,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
  useExpanded,
  useColumnOrder
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { Button, Spinner } from "react-bootstrap";
import {
  faLongArrowAltUp,
  faLongArrowAltDown,
  faDownload,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { VariableSizeList } from "react-window";
import HoverableTableRow from "../../common/HoverableTableRow";
import { flattenArrayOfObject } from "../../../utils/Helper";
import { channelMapper } from "../../../constants/Constants";
import { makeApiRequest } from "../../../utils/ApiHelper";
import { DateRangeContext } from "../../../contexts/common/DateRangeContext";
import {withRouter} from "react-router-dom";
import { DOWNLOAD_GRID_AS_EXCEL_ENDPOINT } from "../../../EndPoints";
import * as Analytics from '../../common/Analytics';

import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'
import fielddetails from './fielddetails'

function GridGenerator({
  columns,
  data,
  allData,
  totals,
  colMinWidth,
  gridHeight,
  gridType,
  virtualizedRow,
  cellHeight,
  gridId,
  gridLabel,
  exportGridId,
  toolTipInfo,
  colWidth,
  hoverableRow,
  hoveredClickableCell,
  queryKey,
  expandedConfig,
  hideFooter,
  showLoadMoreButton,
  onLoadMoreButtonClicked,
  currentPageIndex,
  totalPages,
  paginatedDataLoaded,
  match
}) {
  const [adjustableGridHeight, setAdjustableGridHeight] = useState(gridHeight);
  const [isLoading, setIsLoading] = useState({ loading: false });
  const { activeDateRange } = useContext(DateRangeContext);
  const [minimizedRowCountConfig, setMinimizedRowCountConfig] = useState({});

  let draggedConfig = {};
  if (toolTipInfo && columns) {
    columns = columns.map(col => {
      if (toolTipInfo[col["accessor"]]) {
        col[
          "toolTipInfo"
        ] = `<span class="tooltip-head">${col["Header"]}</span><br>`;

        if(window.location.pathname.includes("social_influencer") || window.location.pathname.includes("social_display") && col["accessor"] === 'firstActionRatePercent') {
          col["toolTipInfo"] += `<span class="tooltip-body">Percentage of impressions where an interaction was made.</span>`;
        } else {
          col["toolTipInfo"] += `<span class="tooltip-body">${
            toolTipInfo[col["accessor"]]
          }</span>`;
        }
      }

      return col;
    });
  }
  const handleColumnDragAndDrop = e => {
    const headerClass = `.${gridId}-table-header`;
    const headers = Array.prototype.slice.call(
      document.querySelectorAll(headerClass)
    );

    headers.forEach((header, i) => {
      header.setAttribute("draggable", true);
      header.ondrag = e => e.stopPropagation();
      header.ondragend = e => e.stopPropagation();
      header.ondragover = e => e.preventDefault();

      header.ondragstart = e => {
        e.stopPropagation();
        draggedConfig.draggedCol = i;
        e.dataTransfer.setData("text", "fix firefox dragevents");
      };

      header.ondrop = e => {
        e.preventDefault();
        document.getElementById("ele-hover-class").remove();

        const index = draggedConfig.draggedCol >= i ? i : i - 1;
        flatColumns.splice(
          index,
          0,
          flatColumns.splice(draggedConfig.draggedCol, 1)[0]
        );

        setColumnOrder(flatColumns.map(d => d.id));
      };
    });
  };

  const onEleDragOver = event => {
    event.preventDefault();
    if (document.getElementById("ele-hover-class")) return;

    var thElement = event.target;
    if(thElement.innerText == "") { return; }
    if(thElement.tagName == 'SPAN') {
      thElement = event.target.parentElement;
    }

    var arrowsIndicator = document.createElement("div");
    arrowsIndicator.classList.add(["ele-hover-class"]);
    arrowsIndicator.innerHTML = "";
    arrowsIndicator.setAttribute("id", "ele-hover-class");
    if (thElement) {
      thElement.parentNode.insertBefore(arrowsIndicator, thElement);
    }
  };

  const onEleDragLeave = event => {
    event.preventDefault();
    const eleHoverClassElement = document.getElementById("ele-hover-class");
    eleHoverClassElement && eleHoverClassElement.remove();
  };
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: colMinWidth, // minWidth is only used as a limit for resizing
      width: colWidth // width is used for both the flex-basis and flex-grow
      //   maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    [colMinWidth, colWidth]
  );
  const hiddenColumns = [];
  columns.forEach(column => {
    if (column.hide) hiddenColumns.push(column.accessor);
  });
  const {
    getTableProps,
    headerGroups,
    footerGroups,
    rows,
    totalColumnsWidth,
    flatColumns,
    prepareRow,
    setColumnOrder
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        expanded: expandedConfig ? expandedConfig : {},
        hiddenColumns: hiddenColumns
      }
    },
    useSortBy,
    useFlexLayout,
    useResizeColumns,
    useExpanded,
    useColumnOrder
  );

  // Render the UI for your table
  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      let curRowProps = row.getRowProps;
      if (curRowProps === undefined) curRowProps = row.getRowProps();
      const query = (row && row.original && row.original[queryKey]) || "";
      const queryToLower = query && query.replace(/ /g, "-").toLowerCase();
      let queryParams;
      if (hoverableRow && row.original["channel"] !== "Search & Sponsored" && row.original["channel"] !== "Overall" && channelMapper[queryToLower]) {
          queryParams = {
          originalQuery: query,
          convertedQuery:
            channelMapper[queryToLower][
              "query"
            ]
        };
        return (
          <HoverableTableRow
            key={index}
            index={index}
            row={row}
            queryToBePassed={queryParams}
            curRowProps={curRowProps}
            style={style}
            routeTo={queryKey}
            hoveredClickableCell={hoveredClickableCell}
            gridLabel={gridLabel}
          />
        );
      }
        else {
        return (
          <div
            className={"tr" + (!(index % 2) ? " striped-white" : "")}
            {...curRowProps({
              style
            })}
          >
            {row.cells.map(cell => {
              let cellProps = cell.getCellProps();
              const extraTdClass = cell.column.tdClassName
                ? cell.column.tdClassName
                : "";
              return (
                <div
                  className={"td " + extraTdClass}
                  {...cellProps}
                  data-child-count={row.subRows.length}
                  onClick={e => {
                    let copyOfRow = row;
                    let count = copyOfRow.subRows.length;
                    copyOfRow = flattenArrayOfObject(copyOfRow.subRows);
                    copyOfRow.forEach(data => {
                      if (data.isExpanded) {
                        if (data.subRows) {
                          count += data.subRows.length - 1;
                        }
                        count++;
                      }
                    });
                    if(row.isExpanded) {
                      setMinimizedRowCountConfig({...minimizedRowCountConfig, ...{
                        [row.id]:count
                      }})
                    }
                    else if (minimizedRowCountConfig[row.id]) {
                      count = minimizedRowCountConfig[row.id]
                    }
                    const firstChild =
                      e &&
                      e.currentTarget &&
                      e.currentTarget.firstElementChild &&
                      e.currentTarget.firstElementChild.className;
                    if (firstChild && firstChild.includes("tree-grid-row")) {
                      if (count) {
                        let newHeight = 0;
                        if (row.isExpanded) {
                          newHeight =
                            adjustableGridHeight - parseInt(count) * 45;
                        } else {
                          newHeight =
                            adjustableGridHeight + parseInt(count) * 45;
                        }

                        setAdjustableGridHeight(newHeight);
                      }
                    }
                  }}
                >
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
        );
      }
    },
    [prepareRow, rows, adjustableGridHeight, hoverableRow, queryKey]
  );

  // const downloadExcel = () => {
  //   Analytics.track("Download " + gridLabel, "Download Grid");
  //   let specificChannel = match && match.params && match.params.id;
  //   specificChannel = specificChannel == undefined ? "" : specificChannel;
  //   const url = DOWNLOAD_GRID_AS_EXCEL_ENDPOINT
  //      + "?range=" + activeDateRange.range 
  //      + "&gridtodownload=" + exportGridId
  //      + "&channel=" + specificChannel;

  //   makeApiRequest(url, "getblob", {
  //     cmpId: ["download_" + gridId],
  //     setIsLoading: setIsLoading
  //   }).then(data => {
  //     var URL = window.URL || window.webkitURL;
  //     var downloadUrl = URL.createObjectURL(data.data);
      
  //     var a = document.createElement("a");
  //     a.href = downloadUrl;
  //     a.download = exportGridId + "_" + specificChannel + "_" + new Date().toDateString() + ".xls";
  //     document.body.appendChild(a);
  //     a.click();
  //     setIsLoading({ loading: false });
  //   }).catch(err => {
  //     setIsLoading({ loading: false });
  //   });
  // };

  const downloadExcel = async () =>  {
    Analytics.track("Download " + gridLabel, "Download Grid");
    const wb = new ExcelJS.Workbook()

    const ws = wb.addWorksheet()

    let csvData = rows.map(x => x.values);
    ws.addRow(['Media Business Dashboard Report']).font = { bold: true }
    ws.addRow([])
    ws.addRow(['Date Range', activeDateRange.rangeValue])
    ws.addRow(['Start Date', activeDateRange.startDate])
    ws.addRow(['End Date', activeDateRange.endDate])
    ws.addRow(['Run Date', new Date().toLocaleDateString()])
    ws.addRow([])

    const columnKeys= {};
    columns = [...columns.filter(x => !x.hide), ...columns.filter(x => x.hide)];
    columns.forEach(x => {
      if(x.accessor.toLowerCase().includes("percent") 
          && fielddetails[x.accessor]?.label.includes("dynamic")
          && !x.Header.toLowerCase().includes("percent")
          && !x.Header.toLowerCase().includes("%") ) {
        x.Header = x.Header + " %"
      }
      if(fielddetails[x.accessor]?.label.includes("dynamic") || !fielddetails[x.accessor]?.label) {
        columnKeys[x.accessor] = x.Header
      }
      else
        columnKeys[x.accessor] = fielddetails[x.accessor]?.label
    })
    const keys = Object.values(columnKeys)

    ws.addRow(keys).font = { bold: true }
    if(gridId === "retailer-grid") {
      csvData = footerGroups[0].headers.map(column => column.render("Footer"))[0].props.flatRows.map(row => {
        row.values.gridParentCol = row.values.gridParentCol.padStart(row.values.gridParentCol.length + (row.depth * 5), ' ')
        return row.values
        })
    }
    else if(gridId === "campaign-performance-grid") {
      csvData = allData
    }
    csvData.forEach(x => {
      const rowData = Object.keys(columnKeys).map(key => x[key])
      ws.addRow(rowData)
    })

    const totalsRow = Object.keys(columnKeys).map(key => totals && (totals[key]||"Totals"))
    ws.addRow(Object.values(totalsRow)).font = { bold: true }

    for (let i = 0; i < ws.columns.length; i += 1) { 
      let dataMax = 0;
      const column = ws.columns[i];
      for (let j = 1; j < column.values.length; j += 1) {
        const columnLength = column.values[j]?.length;
        if (columnLength > dataMax) {
          dataMax = columnLength + 5;
        }
      }
      column.width = dataMax < 10 ? 10 : dataMax;
      const currentColumnKey = Object.keys(columnKeys)[i]
      if(fielddetails[currentColumnKey]?.type === "number") {
        column.numFmt = '#,##0;[Red]-#,##0';
      }
      else if(fielddetails[currentColumnKey]?.type === "float") {
        column.numFmt = '#,##0.00;[Red]-#,##0.00';
      }
      // if(!currentColumnKey.includes("percent"))
      //   column.numFmt = '#,##0.00;[Red]-#,##0.00';
    }

    const buf = await wb.xlsx.writeBuffer()
    let specificChannel = match && match.params && match.params.id;
    specificChannel = specificChannel == undefined ? "" : specificChannel;
    const fileName = gridId + "_" + specificChannel + "_" + new Date().toLocaleDateString() + ".xlsx"
    saveAs(new Blob([buf]), fileName)
}

  return (
    <div className="grid-container">
      <div>
        <Button
          style={{cursor: isLoading.loading ? "not-allowed" : ""}}
          disabled={isLoading.loading}
          id={"download_" + gridId}
          className="float-right mb-1"
          onClick={() => downloadExcel()}
          variant="outline-dark"
          data-tip={"Download as Excel"}
        >
          {isLoading.loading ? (
            <FontAwesomeIcon className="grid-sort-icon fa-spin" icon={faSpinner} />
          ) : (
            <FontAwesomeIcon className="grid-sort-icon" icon={faDownload} />
          )}
        </Button>
      </div>
      <ReactTooltip />

      <div
        {...getTableProps()}
        className={`table table-responsive ${gridType}`}
      >
        <div className="thead">
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column, index) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props

                <div
                  className={`th ${column.tdClassName} ${
                    index === 0 ? "first-th" : ""
                  } ${gridId}-table-header`}
                  onDragOver={onEleDragOver}
                  onDragLeave={onEleDragLeave}
                  onMouseDown={handleColumnDragAndDrop}

                  // onMouseDown={
                  //   column.restrictReOrder
                  //     ? () => {
                  //         return false;
                  //       }
                  //     : handleColumnDragAndDrop
                  // }
                  {...column.getHeaderProps()}
                >
                  {
                    <span
                      {...column.getSortByToggleProps()}
                      title=""
                      data-tip={column.toolTipInfo}
                      data-html="true"
                    >
                      {column.render("Header")}
                    </span>
                  }
                  {/* Add a sort direction indicator */}
                  <span className="grid-sort-icon-wrapper">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          className="grid-sort-icon"
                          icon={faLongArrowAltDown}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="grid-sort-icon"
                          icon={faLongArrowAltUp}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? "isResizing" : ""
                    }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {virtualizedRow ? (
            <VariableSizeList
              height={
                gridType === "tree"
                  ? adjustableGridHeight
                  : adjustableGridHeight > gridHeight
                  ? adjustableGridHeight
                  : gridHeight
              }
              itemCount={rows.length}
              itemSize={() => {
                if (cellHeight) {
                  return cellHeight;
                }
                return 45;
              }}
              width={totalColumnsWidth}
            >
              {RenderRow}
            </VariableSizeList>
          ) : (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <div
                  {...row.getRowProps()}
                  className={"tr" + (!(i % 2) ? " striped-white" : "")}
                >
                  {row.cells.map(cell => {
                    return (
                      <div
                        className={"td" + (!(i % 2) ? " striped-white" : "")}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })
          )}
        </div>
        {hideFooter ? (
          ""
        ) : (
          <div className="tfoot">
            {footerGroups.map((group, index) => {
              return (
                <div key={index}>
                  {index === 0 ? (
                    <div
                      key={index}
                      {...group.getFooterGroupProps()}
                      className="tr"
                    >
                      {group.headers.map(column => {
                        const footerProps = column.getFooterProps();
                        if (column.width) {
                          column.width =
                            column.width > column.minWidth
                              ? column.width
                              : column.minWidth;
                          footerProps.style = {
                            ...footerProps.style,
                            ...{
                              width: column.width,
                              flex: column.width + " 0 auto"
                            }
                          };
                        }
                        const extraTdClass = column.tdClassName
                          ? column.tdClassName
                          : "";
                        return (
                          <div
                            className={"td " + extraTdClass}
                            {...footerProps}
                          >
                            {column.render("Footer")}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {showLoadMoreButton ? (
        <div
          className="text-center mt-5 load-more-button"
          hidden={
            currentPageIndex === totalPages || totalPages === 0 ? true : false
          }
        >
          <Button
            variant="outline-dark"
            id={`${gridId}-load-more-button`}
            htmlFor={`${gridId}-load-more-spinner`}
            hidden={paginatedDataLoaded}
            onClick={onLoadMoreButtonClicked}
          >
            LOAD MORE
          </Button>
          <Spinner
            id={`${gridId}-load-more-spinner`}
            animation="grow"
            role="status"
            hidden={!paginatedDataLoaded}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}


export default withRouter(GridGenerator);