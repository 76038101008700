export const MEDIA_URL_PREFIX = "Dashboard/Media/";
export const OVERALL_CHANNEL_ENDPOINT =
  MEDIA_URL_PREFIX + "allchannelsmetrics/result.json";
export const RETAILER_ENDPOINT =
  MEDIA_URL_PREFIX + "retailersmetrics/result.json";
export const ACCOUNT_ENDPOINT =
  MEDIA_URL_PREFIX + "accountsmetrics/result.json";
export const CHANNEL_GRAPHS_ENDPOINT =
  MEDIA_URL_PREFIX + "channelsmetrictimechart/result.json";
export const USER_INFO_ENDPOINT = "/Saml/Sso/getUserDetails";
export const SPECIFIC_CHANNEL_ENDPOINT =
  MEDIA_URL_PREFIX + "toplinemetrics/result.json";
export const CAMPAIGN_PERFORMANCE_ENDPOINT =
  MEDIA_URL_PREFIX + "channelfilteredcampaigns/result.json";
export const SPECIFIC_CHANNEL_GRAPHS_ENDPOINT =
  MEDIA_URL_PREFIX + "channelfilteredtimechart/result.json";
export const SPECIFIC_CHANNEL_DSP_COST_BREAKDOWN_ENDPOINT =
  MEDIA_URL_PREFIX + "channelfilteredcostdsp/result.json";
export const DOWNLOAD_GRID_AS_EXCEL_ENDPOINT =
  MEDIA_URL_PREFIX + "downloadtoexcel/result.json";