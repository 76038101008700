import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function Footer() {
  return (
    <Container>
      {/* <h6> Fine Prints</h6> */}
      <Row>
        <Col md={6}>
          <h6> Programmatic Display, Coupons.com, Retailer.com </h6>
          <ul>
            <li>
              MoCA Engage numbers are considered 1st Party billed. DFP or
              AppNexus numbers are not currently reflected within billing
              numbers for Onsite and Desktop 1st Party billed campaigns.
            </li>
            <li>
              The channel is assigned to line items during campaign setup in
              MoCA for Programmatic Display, Retailer.com and Coupons.com.
              Booked revenue is included in Programmatic Display by default.
              However, once channels are assigned in MoCA, booked revenue will
              populate in the appropriate channels.
            </li>
            <li>For package line items, a sell rate of $6.50 is applied.</li>
            <li>
              For Coupons.com and Retailer.com channels, $0.06CPM cost is
              applied for ad serving fees.
            </li>
          </ul>
        </Col>
        <Col md={6}>
          <h6> Overall Dashboard </h6>
          <ul>
            <li>Excludes 3rd party sold programmatic and ABSCO Agency.</li>
            <li>
              The revenue across all channels is estimated based on delivery and
              does not reflect any adjustments in payouts, etc.
            </li>
            <li>
              Booked revenue is based on SFDC insertion orders within the
              “Closed Won” stage.
            </li>
            <li>
              Content revenue is recognized at the point of completion in Muse.
              Generally, that occurs prior to line item start date. The data
              displayed in each time range is dependent on whether the line item
              is live and this may cause an increase in revenue delivered
              between Quarter to Date and Current Quarter date ranges for the
              Social Influencer channel.
            </li>
            <li>
              For Elevaate data, the booked revenue is based on what was booked
              for the time period within the Elevaate platform and is not based
              on a direct sync with SFDC.
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="text-center copyright">
        <Col md={12}>
          Copyright © 2020 Quotient Technology Inc. All Rights Reserved.
          <br />
          Confidential and Proprietary Information of Quotient Technology Inc. –
          Please do not copy or distribute.{" "}
        </Col>
      </Row>
    </Container>
  );
}
