import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";

export default function DropdownGenerator({ dropdownOptions, dropdownTitle, onSelect }) {
  return (
    <DropdownButton
      size="sm"
      className="basic-dropdown d-inline mr-2 font2-bold"
      variant="secondary"
      title={dropdownTitle}
    >
      {dropdownOptions.map((option, index) => {
        return (
          <Dropdown.Item
            key={`dropdown-${index}`}
            className={`small ${option.label === dropdownTitle ? "active" : ""}`}
            onSelect={onSelect}
            eventKey={option.key}
          >
            {option.label}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
}
