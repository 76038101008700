import React from "react";
import { Line } from "react-chartjs-2";
import variables from "../../../sass/_default-variable.scss";
import { nFormatter } from "../../../utils/Helper";
import { __COLORS_FOR_GRAPHS__ } from "../../../constants/Constants";
import moment from "moment";

const font2Bold = variables["font2-bold"];
// let y_axisPoint = null;

function argMax(array) {
  return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
}

let globalData = {};
let globalConfig = {};

function LineChart({ data, config }) {
  globalData = data;
  globalConfig = config;
  
  let colors = config.colors || [...__COLORS_FOR_GRAPHS__];

  // change max color
  if (data && data.averages)
    colors[argMax(data.averages.map(x => parseFloat(x)))] = "#38a384";

  const dataSetsArray =
    !data || !data.data || !data.data.length
      ? []
      : data.data.map((arrayData, index) => {
          const legend = data.legends[index];
          const color = colors[index]
            ? colors[index]
            : colors[colors.length - index];
          return {
            label: legend,
            backgroundColor: color,
            borderColor: color,
            fill: false,
            borderDash: legend === config.dottedTypeLine ? [5, 5] : [],
            data: arrayData,
            pointStyle: legend === config.showSquareLegendStyle && "rect"
          };
        });
  //Need to pass cloned array, otherwise it's passed as reference and changes the data
  const dataSetsArrayCloned = JSON.parse(JSON.stringify(dataSetsArray));

  return (
    <div
      style={{ backgroundColor: config.backgroundColor }}
      className={config.containerClassNames}
    >
      <Line
        plugins={[
          {
            beforeInit: function(chart, options) {
              chart.legend.afterFit = function() {
                this.height = this.height + 40;
              };
            },
            afterDatasetsDraw: function(chart) {
              if (chart.tooltip._active && chart.tooltip._active.length) {
                var activePoint = chart.tooltip._active[0],
                  ctx = chart.ctx,
                  // ctx2 = chart.ctx,
                  // y_axis = chart.scales["y-axis-0"],
                  // x_axis = chart.scales["x-axis-0"],
                  x = activePoint.tooltipPosition().x;
                // y = chart.tooltip._active
                //   .map(point => point._view.y)
                //   .reduce(function(prev, curr) {
                //     return Math.abs(curr - y_axisPoint) <
                //       Math.abs(prev - y_axisPoint)
                //       ? curr
                //       : prev;
                //   });
                // const leftX = chart.chartArea.left;
                const topY = chart.chartArea.top;
                // const RightX = chart.chartArea.right;
                const bottomY = chart.chartArea.bottom;
                ctx.beginPath();
                ctx.moveTo(x, topY);
                ctx.lineTo(x, bottomY);
                //ctx.moveTo(leftX, y);
                //ctx.lineTo(RightX, y);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "blue";
                ctx.stroke();
                ctx.closePath();
              }
            }
          }
        ]}
        data={{
          labels: data && data.labels,
          datasets: dataSetsArrayCloned
        }}
        width={config.width}
        height={config.height}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            align: config.legendAlignment,
            display: true,
            position: "top",
            labels: {
              padding: 17,
              boxWidth: 7,
              usePointStyle: true,
              fontFamily: font2Bold
            }
          },
          chartArea: {
            backgroundColor: "#efefef"
          },
          elements: {
            point: {
              radius: 0
            },
            line: {
              tension: 0
            }
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: config.yAxeslabelString || ""
                },
                ticks: {
                  beginAtZero: true,
                  autoSkip: false,
                  callback: (value, index, values) => {
                    return nFormatter(value);
                  },
                  maxTicksLimit: config.yAxesMaxTicksLimit
                },
                gridLines: {
                  display: config.yAxesGridLinesShow,
                  drawBorder: false
                }
              }
            ],
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                  maxRotation: config.showStartAndEndDates ? 0 : 180,
                  minRotation: 0,
                  userCallback: function(value, index, values) {
                    //return value;
                    if (config.showStartAndEndDates)
                      return index === 0 || index === values.length - 1
                        ? value
                        : "";
                    else return value;
                  }
                },
                gridLines: {
                  display: config.xAxesGridLinesShow
                }
              }
            ]
          },
          onHover: function(event) {
            // y_axisPoint = event.y;
          },
          tooltips: {
            mode: "x-axis",
            enabled: false,
            custom: customTooltip
          }
        }}
      />
    </div>
  );
}

const customTooltip = function(tooltipModel) {
  // Tooltip Element
  var tooltipEl = document.getElementById("chartjs-tooltip");

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.style = `
          //min-width: 250px;
          opacity: 1;
          position: absolute;
          background: #475158;
          color: white;
          -webkit-transition: all .1s ease;
          transition: all .1s ease;
          pointer-events: none;
          -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
        `;
    tooltipEl.innerHTML = '<table style="margin: 5px"></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    var titleLines = tooltipModel.title || [];
    var bodyLines = tooltipModel.body.map(getBody);

    var innerHtml = '<thead style="letter-spacing: .1em">';

    titleLines.forEach(function(title) {
      if (globalData.chartdataType === "weekly") {
        const indexOfTitle =
          globalData.labels && globalData.labels.indexOf(title);
        if (indexOfTitle > -1 && globalData.labels[indexOfTitle + 1]) {
          title =
            title +
            " - " +
            moment(globalData.labels[indexOfTitle + 1], "MM/DD/YYYY")
              .add(-1, "days")
              .format("MM/DD/YYYY");
        } else {
          title =
            title +
            " - " +
            moment(globalConfig.dateRangeEndDate, "MM/DD/YYYY").format(
              "MM/DD/YYYY"
            );
        }
      }
      innerHtml +=
        '<tr><td style="color: lightgrey; padding-bottom:15px">' +
        globalConfig.tooltipDescriptionLabel +
        " For " +
        title +
        "</td></tr>";
    });
    innerHtml += '</thead><tbody style="letter-spacing: .1em">';

    const sumOfAll = tooltipModel.dataPoints
      .map(x => x.yLabel)
      .reduce((a, b) => a + b, 0);

    var style = " border-width: 2px";
    style += "; border-radius: 50px";
    style += "; margin-right: 5px";
    style += "; width: 10px";
    style += "; height: 10px";
    style += "; display: inline-block";

    const isRevenue = globalConfig.tooltipDescriptionLabel && globalConfig.tooltipDescriptionLabel.includes("Revenue");

    bodyLines.forEach(function(body, i) {
      var colors = tooltipModel.labelColors[i];
      let currentStyle = style;
      currentStyle += "; background: " + colors.backgroundColor;
      currentStyle += "; color: " + colors.borderColor;
      currentStyle += "; background-color: " + colors.borderColor;
      currentStyle += "; border-color: " + colors.borderColor;
      const labelAndValue = body.toString().split(":");
      const currentLabel = labelAndValue[0];
      const currentValue = labelAndValue[1];
      if(currentLabel === globalConfig.showSquareLegendStyle)
        currentStyle += "; border-radius: 0px";

      var div = '<div style="' + currentStyle + '"></div>';
      innerHtml +=
        "<tr>" +
        '<td style="padding-bottom: 10px">' +
        div +
        currentLabel +
        "</td>" +
        '<td class="pr-1 pl-1" style="text-align: right; padding-bottom: 10px"><span class="font2-bold">' +
        globalConfig.tooltipPrepend +
        nFormatter(currentValue, globalConfig.pointsAfterDecimal, {isRevenue}) +
        "</span></td>" +
        '<td class="pr-1 pl-1" style="text-align: right; padding-bottom: 10px"><span class="font2-bold">' +
        (globalConfig.tooltipShowPercentages
          ? (((parseFloat(currentValue) / sumOfAll) * 100) || 0).toFixed(0) + "%"
          : "") +
        "</span></td>" +
        "</tr>";
    });
    let summaryValue = "";
    let summaryPercentageValue = "";
    switch (globalConfig.tooltipSummaryLabel) {
      case "Total":
        summaryValue = globalConfig.tooltipPrepend + nFormatter(sumOfAll, globalConfig.summaryPointsAfterDecimal, {isRevenue});
        summaryPercentageValue = "100%";
        break;
      case "Average":
        summaryValue = globalConfig.tooltipPrepend + nFormatter(sumOfAll / bodyLines.length, globalConfig.summaryPointsAfterDecimal, {isRevenue});
        break;
      case "Delivered vs. Booked":
        summaryValue = nFormatter(globalConfig.calculateTooltipSummaryValue(bodyLines), globalConfig.summaryPointsAfterDecimal, {}) + "%";
        break;
      default:
        break;
    }
    if(globalConfig.showTooltipSummary) {
      innerHtml +=
        "<tr>" +
        '<td style="padding-bottom: 10px">' +
        '<div style="padding-left: 17px;' +
        //style +
        '"><b>' +
        globalConfig.tooltipSummaryLabel +
        "</b></div>" +
        "</td>" +
        '<td class="pr-1 pl-1" style="text-align: right; padding-bottom: 10px"><span class="font2-bold">' +
        summaryValue +
        "</span></td>" +
        '<td class="pr-1 pl-1" style="text-align: right; padding-bottom: 10px"><span class="font2-bold">' +
        (globalConfig.tooltipShowPercentages ? summaryPercentageValue : "") +
        "</span></td>" +
        "</tr>";
    }
    innerHtml += "</tbody>";

    var tableRoot = tooltipEl.querySelector("table");
    tableRoot.innerHTML = innerHtml;
  }

  // `this` will be the overall tooltip
  var position = this._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  let tooltipLeft =
    position.left + window.pageXOffset + tooltipModel.caretX + 180;
  if (window.innerWidth - 250 < tooltipLeft)
    tooltipLeft = window.innerWidth - 300;

  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left = tooltipLeft + "px";
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltipModel.caretY - 150 + "px";
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding =
    tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
  tooltipEl.style.pointerEvents = "none";
  tooltipEl.style.width = "340px"
};

export default LineChart;
