import React from "react";
import { Container } from "react-bootstrap";
import DspBreakDownGrid from "./DspBreakDownGrid";

export default function DspBreakDown({ sectionHeading, activeDateRange }) {
  return (
    <div className="section-style">
      <div id="account" className="section-style gray">
        <Container
          className="account-section-container"
          style={{ letterSpacing: ".1em" }}
        >
          <h1 className="text-center heading">{sectionHeading}</h1>
          <DspBreakDownGrid activeDateRange={activeDateRange} />
        </Container>
      </div>
    </div>
  );
}
