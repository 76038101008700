import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useLocation, withRouter } from "react-router-dom";
import { channelMapper } from "../../constants/Constants";
import * as Analytics from "../common/Analytics";

function ChannelDropdown({ history }) {
  const urlInfo = useLocation();
  const windowPath = urlInfo && urlInfo.pathname;

  const onChannelDropdownOptionSelect = e => {
    e.preventDefault();
    const targetUrl = e.target.dataset.href;
    Analytics.track("Go to " + targetUrl, "Channel Dropdown Selection");
    history.push(targetUrl);
  };

  const channelDropdownData = [
    <Dropdown.Item
      key={`channel-dropdown-home`}
      data-href={`/`}
      onClick={onChannelDropdownOptionSelect}
      active={history.location.pathname === "/" ? true : false}
    >
      Home
    </Dropdown.Item>
  ];

  for (let key in channelMapper) {
    if (key !== "search-&-sponsored") {
      channelDropdownData.push(
        <Dropdown.Item
          key={`channel-dropdown-${key}`}
          data-href={`/channel/${channelMapper[key]["query"]}`}
          onClick={onChannelDropdownOptionSelect}
          active={
            windowPath.includes(channelMapper[key]["query"]) ? true : false
          }
        >
          {channelMapper[key]["name"]}
        </Dropdown.Item>
      );
    }
  }

  return (
    <span id="channel-dropdown-wrapper">
      <button
        aria-controls="channel-options-hamburger"
        id="channel-options-hamburger"
        type="button"
        aria-label="Toggle navigation"
        className="navbar-toggler collapsed"
        style={{ position: "absolute" }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <DropdownButton
        id="channel-options-dropdown"
        title=""
        variant="secondary"
      >
        {channelDropdownData.map(dropdown => dropdown)}
      </DropdownButton>
    </span>
  );
}
export default withRouter(ChannelDropdown);
