import React, { createContext, useState } from "react";
import {
  getDefaultDateRanges,
  getInitialDateRange
} from "../../components/home/NavMenuDateRange";

export const DateRangeContext = createContext();

export const DateRangeContextProvider = props => {
  const ranges = getDefaultDateRanges();
  const initialDateRange = getInitialDateRange(ranges);
  const [dateRange, setDateRange] = useState(initialDateRange);

  const onDateRangeValChange = dateRange => {
    setDateRange({ ...initialDateRange, ...dateRange });
  };

  return (
    <DateRangeContext.Provider
      value={{
        ranges: ranges,
        activeDateRange: dateRange,
        dateRangeHandler: onDateRangeValChange
      }}
    >
      {props.children}
    </DateRangeContext.Provider>
  );
};
