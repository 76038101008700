import React, { useState } from "react";
import { withRouter } from "react-router-dom";

export const track = (eventAction, eventCategory) => { 
    const data = {
        eventAction,
        eventCategory
    };
    if(!data.hitType){
        data['hitType'] = 'event';
    }
    if(!data.eventAction){
        data['eventAction'] = 'Click'; 
    }
    data.eventLabel = window["__media_dash_config__"]["user_email"];
    trackEvent(data);
}

const trackEvent = (data) => {
    if (
        window["__media_dash_config__"]["googleAnalyticsUrls"].indexOf(
          window.location.host
        ) > -1
      ) {
        if (window.gtag) {
            window.gtag(data['hitType'], data['eventAction'], {
                'event_category' : data['eventCategory'],
                'event_label' : data['eventLabel']
            });
        }
    }
}
