import React from "react";
import { Row, Col } from "react-bootstrap";
import { numberFormatter } from "../../../utils/Helper";

export default function OverallMetricsGenerator({ objMapper, data }) {
  return (
    <Row className="text-center data-container">
      {objMapper.map((obj, index) => {
        let val = data[obj["apiKey"]];
        if (typeof obj["apiKey"] === "object") {
          if (obj["apiKey"]["apiKey"]) {
            val = [];
            obj["apiKey"]["apiKey"].forEach(key => {
              val.push(data[key]);
            }, val);
            val = val.join(obj["apiKey"]["separator"]);
          }
          // val = topLineMetricsData;
        }
        return (
          <Col
            md={obj.colSize}
            className="col-wrapper col-md-offset-1"
            key={index}
          >
            <div className="label">{obj.label}</div>
            <div className="number">
              {numberFormatter({
                type: obj.type,
                val: val,
                displayType: obj.displayType,
                thousandSeparator: obj.thousandSeparator,
                prefix: obj.prefix,
                suffix: obj.suffix,
                extraClass: obj.extraClass,
                noDigitsAfterDecimal: obj.noDigitsAfterDecimal,
                digitsAfterDecimal: obj.digitsAfterDecimal
              })}
            </div>
            {obj.child ? (
              <div className="child-wrapper">
                <span className="pr-1 text-uppercase bold">
                  {obj["child"]["prependToVal"]}
                </span>
                {numberFormatter({
                  type: obj.child.type,
                  val: data[obj["child"]["apiKey"]],
                  displayType: obj.child.displayType,
                  thousandSeparator: obj.child.thousandSeparator,
                  prefix: obj.child.prefix,
                  suffix: obj.child.suffix,
                  extraClass: obj.child.extraClass,
                  noDigitsAfterDecimal: obj.child.noDigitsAfterDecimal,
                  digitsAfterDecimal: obj.child.digitsAfterDecimal
                })}
                <span className="pl-1 text-uppercase">
                  {obj["child"]["appendToVal"]}
                </span>
              </div>
            ) : (
              ""
            )}
          </Col>
        );
      })}
    </Row>
  );
}
