import React, { useContext } from "react";
import DspBreakDown from "./DspBreakDown";
import CampaignPerformance from "./campaignPerformance/CampaignPerformance";
import { DateRangeContext } from "../../contexts/common/DateRangeContext";
import ToplineSpecificChannel from "./ToplineSpecificChannel";
import RevenueGraphSpecificChannel from "./RevenueGraphSpecificChannel";
import { withRouter } from "react-router-dom";

function ChannelLevelInnerPage({ match }) {
  const { activeDateRange } = useContext(DateRangeContext);
  const specificChannel = match && match.params && match.params.id;

  return (
    <div>
      <ToplineSpecificChannel
        activeDateRange={activeDateRange}
        specificChannel={specificChannel}
      />
      <RevenueGraphSpecificChannel
        activeDateRange={activeDateRange}
        specificChannel={specificChannel}
      />
      {specificChannel === "programmatic" ? (
        <DspBreakDown
          sectionHeading={"DSP Cost Breakdown"}
          activeDateRange={activeDateRange}
        />
      ) : (
        ""
      )}

      <CampaignPerformance
        sectionHeading={"Campaign Performance"}
        activeDateRange={activeDateRange}
      />
    </div>
  );
}

export default withRouter(ChannelLevelInnerPage);
