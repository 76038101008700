import moment from "moment";
import { getQuarterInfo } from "../../utils/Helper";
const configVars = {
  DEFAULT_DATE_RANGE:
    window["__media_dash_config__"] &&
    window["__media_dash_config__"]["DEFAULT_DATE_RANGE"],
};

const quarters = getQuarterInfo();
export const getDefaultDateRanges = () => {
  let defaultDateRanges = {
    "Month to Date": [moment().startOf("month"), moment()],
    "Quarter to Date": [moment().startOf("quarter"), moment().add(1, "days")],
    // Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Previous Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Current Month": [moment().startOf("month"), moment().endOf("month")],

    // "Last 7 Days": [moment().subtract(6, "days"), moment()],
    // "Last 30 Days": [moment().subtract(29, "days"), moment()],
  };

  defaultDateRanges = { ...defaultDateRanges, ...quarters };
  if (new Date().getDate() === 1 || new Date().getDate() === "1") {
    const quarterMonth = [0, 3, 6, 9];
    const currentMonth = new Date().getMonth();
    const hiddenDateRangesOnFirstDay = {
      "Month to Date": "Month to Date Hidden",
      // "Quarter to Date": "Quarter To Date Hidden",
      "Current Month": "Current Month Hidden",
      "Current Quarter": "Current Quarter Hidden",
    };

    if (quarterMonth.indexOf(currentMonth) > -1) {
      hiddenDateRangesOnFirstDay["Quarter to Date"] = "Quarter To Date Hidden";
    }

    let newDefaultRanges = {};

    for (let key in defaultDateRanges) {
      if (hiddenDateRangesOnFirstDay[key]) {
        newDefaultRanges[hiddenDateRangesOnFirstDay[key]] =
          defaultDateRanges[key];
        delete defaultDateRanges[key];
      } else {
        newDefaultRanges[key] = defaultDateRanges[key];
      }
    }
    defaultDateRanges = newDefaultRanges;
  }

  return defaultDateRanges;
};

const dateMapper = {
  yesterday: "Yesterday",
  last_month: "Previous Month",
  current_month: "Current Month",
  quarter_todate: "Quarter to Date",
  month_todate: "Month to Date",
  current_quarter: Object.keys(quarters)[0],
  last_quarter: Object.keys(quarters)[1],
  secondlast_quarter: Object.keys(quarters)[2],
  thirdlast_quarter: Object.keys(quarters)[3],
};

export const getInitialDateRange = (ranges) => {
  const dateRange = configVars["DEFAULT_DATE_RANGE"]
    ? configVars["DEFAULT_DATE_RANGE"]
    : "current_quarter";

  return {
    startDate: moment(ranges[dateMapper[dateRange]][0]["_d"]).format("L"),
    endDate: moment(ranges[dateMapper[dateRange]][1]["_d"]).format("L"),
    rangeValue: dateMapper[dateRange],
    range: configVars["DEFAULT_DATE_RANGE"]
      ? configVars["DEFAULT_DATE_RANGE"]
      : "current_quarter",
  };
};
