export const __MEDIA_DASH_ASSERT_ID__ = "__MEDIA_DASH_ASSERT_ID__";
export const __LAST_SUCCESSFUL_API_CALL_MADE__ =
  "__LAST_SUCCESSFUL_API_CALL_MADE__";

export const __CHANNEL_GRID_TOOLTIP_INFO__ = {
  channel: "The media channel that <br> generated the revenue.",
  impressionsDelivered: "Estimated billable impressions <br> served.",
  avgSaleRate: "Average CPM rate at <br> which plans were sold.",
  revenueDelivered: "Estimated revenue based on delivery.",
  marginPercent:
    "Profit based on estimated revenue <br> delivered and DSP Costs.",
  paceBookedPercent:
    "Percentage of the booked revenue <br> that was delivered.",
  paceForeCastPercent:
    "Percentage of the revenue goal <br> that was delivered ",
  bookedForecastPercent:
    "Percentage of the revenue goal <br> that was booked in SFDC.  ",
  revenuePlanned:
    "SFDC Booked Revenue based on <br> ‘Closed Won’ Stage. A daily average is used <br>to estimate booked revenue <br> across time ranges.",
  revenueForecasted:
    "Revenue goal provided by finance. <br> A daily average is used to estimate revenue <br> goal across time ranges.",
  firstActionRatePercent:
    "First Action Rate within the <br >time range (not capped at billable impressions).",
  viewablePercent:
    "Percentage of impressions where at <br> least 50% of an ad was In-View for <br>  at least one continuous second <br> based on Moat.",
  ivtPercent:
    "Percentage of impressions that were determined <br> to be delivered to an invalid <br> endpoint based on Moat.",
  dspName: "Demand Side Platform utilized to deliver media.",
  mediaCost: "DSP spend for media only.",
  avgMediaBuyRate: "The CPM at which we purchased an impression.",
  dataCost: "DSP spend for data only. Could range from data costs for 3rd party audiences to using brand safety segments.",
  dataCostCPM: "The CPM at which we purchased the data.",
  totalPercent: "Percentage of the total impressions which were served on a given DSP."
};

export const __DSP_GRID_TOOLTIP_INFO__ = {...__CHANNEL_GRID_TOOLTIP_INFO__, ... {
    impressionsDelivered: "Impressions served in the DSP.",
  }
}

export const __COLORS_FOR_GRAPHS__ = [
  "black",
  "#e9b158",
  "#cb4472",
  "#d16239",
  "#8a2968",
  "#73b166",
  "#5794c1",
  "rgb(199, 239, 147)",
  "#699DBA",
  "#9cbab3",
  "#1e1978",
  "#d77087",
  "#c28132",
  "#60caee",
  "#b37adf",
  "#6289d6",
  "#a21b7c",
  "#a42802",
  "#2860e7",
  "#69e0c0"
];

export const channelMapper = {
  "programmatic-display": {
    query: "programmatic",
    name: "Programmatic Display"
  },
  "social-influencer": {
    query: "social_influencer",
    name: "Social Influencer"
  },

  "social-display": { query: "social_display", name: "Social Display" },
  "coupons.com": { query: "coupons", name: "Coupons.com" },
  "retailer.com": { query: "retailers", name: "Retailer.com" },
  "search-&-sponsored": { query: "search", name: "Search & Sponsored" }
};
