import React from "react";
import { Container } from "react-bootstrap";
import CampaignPerformanceGrid from "./CampaignPerformanceGrid";

export default function CampaignPerformance({
  sectionHeading,
  activeDateRange
}) {
  return (
    <div>
      <div id="account" className="section-style">
        <Container
          className="account-section-container"
          style={{ letterSpacing: ".1em" }}
        >
          <h1 className="text-center heading">{sectionHeading}</h1>
        </Container>
        <CampaignPerformanceGrid activeDateRange={activeDateRange} />
      </div>
    </div>
  );
}
