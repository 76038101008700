import React, { useState, useEffect } from "react";
import LineChart from "../../generator/charts/LineChart";
// import BarChart from "../../generator/charts/BarChart";
import { Row, Col } from "react-bootstrap";
import DropdownGenerator from "../../common/DropdownGenerator";
import { Spinner } from "react-bootstrap";
import { makeApiRequest } from "../../../utils/ApiHelper";
import { CHANNEL_GRAPHS_ENDPOINT, SPECIFIC_CHANNEL_GRAPHS_ENDPOINT } from "../../../EndPoints";
import { showToast } from "../../../utils/ApiHelper";
import moment from "moment";
import { calculateTooltipSummaryValue } from "../../channelLevelView/RevenueGraphSpecificChannel";
import * as Analytics from "../../common/Analytics";

export const calculateTooltipEndDate = (
  channelLineChartDataLabels,
  activeDateRangeEndDate
) => {
  if (channelLineChartDataLabels && channelLineChartDataLabels.length) {
    const isShowingWhiteSpace =  channelLineChartDataLabels.includes(undefined)
    if (isShowingWhiteSpace) {
      channelLineChartDataLabels = channelLineChartDataLabels.filter(x => x);
      channelLineChartDataLabels.pop();
    }
    const lastDateInChannelDataPlusSix = moment(
      new Date(
        channelLineChartDataLabels[channelLineChartDataLabels.length - 1]
      )
    ).add(6, "days");
    const todaysDate = moment();
    const diffDays = lastDateInChannelDataPlusSix.diff(todaysDate, "days");
    return diffDays < 0
      ? lastDateInChannelDataPlusSix.format("MM/DD/YYYY")
      : todaysDate.format("MM/DD/YYYY");
  } else return activeDateRangeEndDate;
};

export default function ChannelGraphs({ activeDateRange }) {
  const [channelRawData, setChannelRawData] = useState({});
  const [channelLineChartData, setChannelLineChartData] = useState({});
  const [chartDropdownOptions, setChartDropdownOptions] = useState({});
  const [savedSelectedMetric, setSavedSelectedMetric] = useState(
    "revenueDelivered"
  );
  // const [channelBarChartData, setChannelBarChartData] = useState({});
  const [isLoading, setIsLoading] = useState({ loading: true });

  const parseChartData = (apiData, selectedMetric) => {
    if (selectedMetric !== savedSelectedMetric)
      setSavedSelectedMetric(selectedMetric);

    const currentRange = activeDateRange && activeDateRange.range;
    const chartdataType = apiData && apiData.chartdataType;

    let selectedMetricLabel = "";
    let selectedMetricKey = "";
    const dropdownOptions = apiData.metrics.map(x => {
      if (selectedMetric === x.metric) {
        selectedMetricLabel = x.label;
        selectedMetricKey = x.metric;
      }
      return { label: x.label, key: x.metric };
    });

    const metricData = apiData && apiData.metrics;
    const filteredMetric =
      metricData && metricData.filter(x => x.metric === selectedMetric)[0];

    if (filteredMetric) {
      const averages =
        filteredMetric.averages && filteredMetric.averages.map(x => x.avg);
      const legends = [];
      const metricData = [];
      filteredMetric.dataSet.forEach(dataset => {
        legends.push(dataset.channel);
        metricData.push(dataset.data);
      });
      const labels =
        apiData.dates && apiData.dates.map(x => moment(x).format("MM/DD/YYYY"));

      if (
        ["current_month", "current_quarter"].includes(currentRange) &&
        activeDateRange.startDate &&
        activeDateRange.endDate &&
        window["__media_dash_config__"]["CHANNEL_GRAPH_SHOW_WHITE_SPACE"]
      ) {
        const startDate = moment(activeDateRange.startDate, "M/D/YYYY");
        const endDate = moment(activeDateRange.endDate, "M/D/YYYY");
        const differentDaysOrWeeks =
          chartdataType === "weekly" ? "weeks" : "days";
        const diffDaysOrWeeksCount = endDate.diff(
          startDate,
          differentDaysOrWeeks
        );
        labels.length = diffDaysOrWeeksCount;
        labels[diffDaysOrWeeksCount - 1] = moment(endDate).format("MM/DD/YYYY");
        metricData.forEach(dataSet => (dataSet.length = diffDaysOrWeeksCount));
      }

      return {
        lineChartData: {
          legends,
          averages,
          data: metricData,
          labels,
          chartdataType
        },
        barChartData: {
          labels: legends,
          data: averages
        },
        dropdownOptions: {
          selected: selectedMetricLabel,
          selectedKey: selectedMetricKey,
          options: dropdownOptions
        }
      };
    } else {
      showToast(
        "error",
        "Oops, there was an issue getting data for Channel Line and Bar Charts. If this message persists please contact your administrator."
      );
      return {
        lineChartData: {},
        barChartData: {}
      };
    }
  };

  useEffect(() => {
    const currentRange = activeDateRange && activeDateRange.range;
    if (currentRange !== "yesterday") {
      const url = CHANNEL_GRAPHS_ENDPOINT + "?range=" + currentRange;
      makeApiRequest(url, "get", {
        cmpId: "channel-graph-wrapper",
        setIsLoading: setIsLoading
      }).then(data => {
        const apiData = data && data.data && data.data.data;
        const mappedLabels = {
          revenueForecasted: "Goal",
          revenuePlanned: "Booked",
          revenueDelivered: "Est. Delivered"
        };
        const url = SPECIFIC_CHANNEL_GRAPHS_ENDPOINT + "?range=" + currentRange + "&channel=overall";
        makeApiRequest(url, "get").then(data => {
          const revenuPacingApiData = data && data.data && data.data.data;
          apiData.metrics.push( {metric: "overall", label: "Revenue Pacing", dataSet: revenuPacingApiData.dataSet.map(ds => {
            return { channel: mappedLabels[ds.metric], data: ds.data };
          })});
          setChannelRawData(apiData);
          const parsedApiData = parseChartData(apiData, savedSelectedMetric);
          setChannelLineChartData(parsedApiData.lineChartData);
          setChartDropdownOptions(parsedApiData.dropdownOptions);
          // setChannelBarChartData(parsedApiData.barChartData);
          setIsLoading({ loading: false });
        });
      });
    }
  }, [activeDateRange]);

  const currentRange = activeDateRange && activeDateRange.range;
  const hideCharts =
    currentRange === "yesterday" ||
    (channelLineChartData &&
      channelLineChartData.labels &&
      channelLineChartData.labels.filter(x => x).length <
        (window["__media_dash_config__"].MINIMUM_DAYS_TO_VIEW_CHANNEL_GRAPHS ||
          3));

  const onDropdownSelect = (value, event) => {
    Analytics.track("View " + event.target.text + " metrics", "Channel Graph metric selection");
    const parsedApiData = parseChartData(channelRawData, value);
    setChannelLineChartData(parsedApiData.lineChartData);
    setChartDropdownOptions(parsedApiData.dropdownOptions);
  };

  let lineChart_yAxesLabelString = "";
  let lineChart_tooltipPrepend = "";
  let lineChart_tooltipSummaryLabel = "";
  let lineChart_tooltipShowPercentages = true;
  let lineChart_colors = undefined;
  let pointsAfterDecimal = 1;
  switch (chartDropdownOptions.selectedKey) {
    case "revenueDelivered":
      lineChart_tooltipSummaryLabel = "Total";
      lineChart_yAxesLabelString = `Est. ${chartDropdownOptions.selected} Delivered ($)`;
      lineChart_tooltipPrepend = "$";
      break;
    case "impressionsDelivered":
      lineChart_tooltipSummaryLabel = "Total";
      lineChart_yAxesLabelString = `${chartDropdownOptions.selected} Delivered`;
      break;
    case "avgSaleRate":
      lineChart_tooltipSummaryLabel = "Average";
      lineChart_yAxesLabelString = `${chartDropdownOptions.selected} Delivered ($)`;
      lineChart_tooltipPrepend = "$";
      lineChart_tooltipShowPercentages = false;
      pointsAfterDecimal = 2;
      break;
    case "overall":
      lineChart_tooltipSummaryLabel = "Delivered vs. Booked";
      lineChart_yAxesLabelString = "Revenue ($)";
      lineChart_colors = ["black", "#e9b158", "#38a384"];
      lineChart_tooltipShowPercentages = false;
      break;
    default:
      lineChart_yAxesLabelString = lineChart_yAxesLabelString;
      break;
  }

  let tooltipEndDate = "";
  if (channelLineChartData.chartdataType === "weekly") {
    tooltipEndDate = calculateTooltipEndDate(
      channelLineChartData.labels,
      activeDateRange.endDate
    );
  }

  return (
    <div id="channel-graph-wrapper">
      {isLoading.loading && !hideCharts ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          {hideCharts ? null : (
            <>
              <div className="channel-graph-wrapper">
                <DropdownGenerator
                  onSelect={onDropdownSelect}
                  dropdownOptions={chartDropdownOptions.options}
                  dropdownTitle={chartDropdownOptions.selected}
                />
              </div>
              <Row>
                <Col lg={12}>
                  <LineChart
                    data={channelLineChartData}
                    config={{
                      calculateTooltipSummaryValue: calculateTooltipSummaryValue,
                      colors: lineChart_colors,
                      pointsAfterDecimal: pointsAfterDecimal,
                      summaryPointsAfterDecimal: pointsAfterDecimal,
                      legendAlignment: "center",
                      showTooltipSummary: true,
                      dateRangeEndDate: tooltipEndDate,
                      tooltipShowPercentages: lineChart_tooltipShowPercentages,
                      tooltipSummaryLabel: lineChart_tooltipSummaryLabel,
                      tooltipPrepend: lineChart_tooltipPrepend,
                      tooltipDescriptionLabel: chartDropdownOptions.selected,
                      width: undefined,
                      height: 400,
                      containerClassNames: "p-4",
                      backgroundColor: "white",
                      dottedTypeLine: "Goal",
                      showSquareLegendStyle: "Goal",
                      showStartAndEndDates: true,
                      yAxesGridLinesShow: true,
                      xAxesGridLinesShow: false,
                      yAxesMaxTicksLimit: 5,
                      yAxeslabelString: lineChart_yAxesLabelString
                    }}
                  />
                </Col>
                {/* <Col
                  lg={{ span: 4, offset: 0 }}
                  md={{ span: 8, offset: 2 }}
                  className="offset"
                >
                  <BarChart
                    data={channelBarChartData}
                    config={{
                      label: "Average",
                      labelString:
                        "AVG. FOR " +
                        (channelLineChartData.labels &&
                          channelLineChartData.labels[0] +
                            " - " +
                            channelLineChartData.labels[
                              channelLineChartData.labels.length - 1
                            ]),
                      width: undefined,
                      height: 400,
                      containerClassNames: "p-4",
                      colors: undefined,
                      //backgroundColor: "white",
                      yAxesGridLinesShow: false,
                      xAxesGridLinesShow: false,
                      yAxesMaxTicksLimit: 5,
                      displayXaxesTicks: false
                    }}
                  />
                </Col> */}
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
}
